import React from "react";
import Container from "../common/Container";
import SectionTitle from "../common/SectionTitle";
import Button from "../common/Button";
import RegularPrice from "../common/RegularPrice";
import HighlightedText from "../common/HighlightedText";
import CurrentPrice from "../common/CurrentPrice";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import classes from "./index.module.scss";

export default function ForAnswersAndQuestions(props) {
  const { title, link, regular_price, current_price, list, description } =
    props;
  return (
    <section>
      <Container>
        <div className={classes.wrapper}>
          <SectionTitle className={classes.title}>{title}</SectionTitle>
          {description ? (
            <div className={classes.description}>
              {description.paragraph.map((item) => (
                <p key={item.id} className={classes.description__text}>
                  {item.text}
                </p>
              ))}
            </div>
          ) : null}
          {list ? (
            <Accordion transition className={classes.accordion}>
              {list.list_item.map((item) => (
                <AccordionItem
                  className={classes.accordion__item}
                  key={item.id}
                  header={<span>{item.title}</span>}
                >
                  <span className={classes.accordion__text}>{item.text}</span>
                </AccordionItem>
              ))}
            </Accordion>
          ) : null}
          <div className={classes.footer}>
            {regular_price || current_price ? (
              <div className={classes.price}>
                {regular_price ? (
                  <RegularPrice>
                    <HighlightedText
                      text={regular_price.price}
                      highlightedClassName={classes.price__regular_highlighted}
                    />
                  </RegularPrice>
                ) : null}
                {current_price ? (
                  <CurrentPrice>
                    <HighlightedText
                      text={current_price.price}
                      highlightedClassName={classes.price__current_highlighted}
                    />
                  </CurrentPrice>
                ) : null}
              </div>
            ) : null}
            {link ? (
              <Button link={link.link} label={link.text} icon={link.icon} />
            ) : null}
          </div>
        </div>
      </Container>
    </section>
  );
}
