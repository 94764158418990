import { Box, Typography } from "@mui/material";
import ModalHeader from "./ModalHeader";
import classes from "./index.module.scss";
import { RedButton, WhiteButton } from "../../../MUI/buttons/button";
import { Link } from "react-router-dom";
import { useGTM } from "../../../../hooks/useGTM";

export default function DowngradeSubscriptionModal({
  cancelDowngradePopup,
  approveDowngradePopup,
}) {
  const { trackEvent } = useGTM();

  const closeButtonLabel = "Close";
  const applyButtonLabel = "Yes, cancel";

  const handleCloseClick = () => {
    trackEvent({
      event: "downgrade_close_button_click",
      action: "click",
      label: closeButtonLabel,
    });
    cancelDowngradePopup();
  };

  const handleYesClick = () => {
    trackEvent({
      event: "downgrade_apply_button_click",
      action: "click",
      label: applyButtonLabel,
    });
    approveDowngradePopup();
  };

  return (
    <Box className={classes.wrapper}>
      <ModalHeader title={"Change plan?"} handleClose={cancelDowngradePopup} />
      <Typography className={classes.text}>
        Downgrade changes will be applied starting next billing period. Until
        then, you can continue using current plan features. Plan information can
        be viewed in your{" "}
        <Link className={classes.link} to={"/profile"}>
          Profile settings
        </Link>
        .
      </Typography>
      <Box className={classes.actions}>
        <WhiteButton
          onClick={handleCloseClick}
          isBig={false}
          variant="contained"
        >
          {closeButtonLabel}
        </WhiteButton>
        <RedButton onClick={handleYesClick} isBig={false} variant="contained">
          {applyButtonLabel}
        </RedButton>
      </Box>
    </Box>
  );
}
