import React, { useState, useEffect } from "react";
import classes from "./index.module.scss"; // Optional CSS for styling

const ProgressiveImage = ({
  imageClassName = "",
  containerClassName = "",
  placeholderSrc,
  src,
  alt,
  ...props
}) => {
  const [imageSrc, setImageSrc] = useState(placeholderSrc || src);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImageSrc(src);
      setIsLoading(false);
    };
  }, [src]);

  return (
    <div className={`${classes.container} ${containerClassName}`.trim()}>
      <img
        {...props}
        src={imageSrc}
        alt={alt}
        className={`${classes.image} ${
          isLoading ? classes.image__loading : classes.image__loaded
        } ${imageClassName}`.trim()}
      />
    </div>
  );
};

export default ProgressiveImage;
