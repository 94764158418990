import { content } from "./content";
import {
  AccentButton,
  WhiteButton,
} from "../../../../components/MUI/buttons/button";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Link } from "react-router-dom";
import { useCallback, useContext, useEffect } from "react";
import { ProgressPopupContext } from "../../../../context/ProgressPopupContext";
import classes from "./index.module.scss";
import { SubscriptionsContext } from "../../../../context/SubscriptionsContext";

export default function ProgressPopup({
  nextCourseId,
  image,
  progress,
  courseId,
  nextCourseSlug,
}) {
  const {
    open,
    handleClose,
    getCurrentPopupContent,
    getCurrentPopupName,
    handleHideAllPopups,
    handleHideCurrentPopup,
    isCurrentPopupHidden,
  } = useContext(ProgressPopupContext);
  const { setCourseRedirectPath, setSelectedCourseId } =
    useContext(SubscriptionsContext);

  const popupName = getCurrentPopupName(progress);
  const popupContent = getCurrentPopupContent({
    popupsContent: content,
    popupName,
  });
  const isPopupHidden = isCurrentPopupHidden({
    popupName: popupName + courseId,
  });

  const handlePopupClose = useCallback(() => {
    handleHideCurrentPopup({ popupName: popupName + courseId });
    handleClose();
  }, [courseId, handleClose, handleHideCurrentPopup, popupName]);

  useEffect(() => {
    return () => handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !open ||
    !nextCourseId ||
    !nextCourseSlug ||
    !popupContent ||
    isPopupHidden ||
    localStorage.getItem("coursePopupPreference")
  )
    return null;

  const { title, text, btn1, btn2 } = popupContent;

  return (
    <div
      className={classes.backdrop}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          handlePopupClose();
        }
      }}
    >
      <div className={classes.modal}>
        <button
          type="button"
          className={classes.close}
          onClick={handlePopupClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5.99632 19.425L4.40694 17.775L9.96979 12L4.40694 6.22501L5.99632 4.57501L11.5592 10.35L17.122 4.57501L18.7114 6.22501L13.1486 12L18.7114 17.775L17.122 19.425L11.5592 13.65L5.99632 19.425Z"
              fill="#3D40D5"
            />
          </svg>
        </button>
        <div className={classes.body}>
          <div className={classes.image}>
            <img src={image} alt="Course" width={472} height={276} />
          </div>
          <div className={classes.content}>
            <div className={classes.text}>
              <h3 className={classes.title}>{title}</h3>
              <p className={classes.description}> {text}</p>
            </div>
            <div className={classes.actions}>
              <AccentButton
                component={Link}
                to={"/subscriptions"}
                isBig={false}
                variant={"contained"}
                fullWidth
                onClick={() => {
                  setCourseRedirectPath(`/courses/${nextCourseSlug}`);
                  setSelectedCourseId(nextCourseId);
                  handlePopupClose();
                }}
              >
                {btn1}
              </AccentButton>
              <WhiteButton
                isBig={false}
                variant={"contained"}
                fullWidth
                onClick={handlePopupClose}
              >
                {btn2}
              </WhiteButton>
            </div>
            <FormControlLabel
              control={
                <Checkbox onChange={handleHideAllPopups} disableRipple />
              }
              className={classes.checkbox}
              label={
                <span className={classes.checkbox__text}>Don’t show again</span>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
