import { createContext, useCallback, useMemo, useRef, useState } from "react";
import useModal from "../components/Common/Modal/useModal";
import { useLocation, useNavigate } from "react-router-dom";
export const SubscriptionsContext = createContext();

export default function SubscriptionsContextProvider({ children }) {
  const cancelPopupRef = useRef();
  const downgradePopupRef = useRef();
  const keepPopupRef = useRef();
  const cancelDowngradePopupRef = useRef();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    handleOpen: handleCancelPopupOpen,
    handleClose: handleCancelPopupClose,
  } = useModal(cancelPopupRef);
  const {
    handleOpen: handleDowngradePopupOpen,
    handleClose: handleDowngradePopupClose,
  } = useModal(downgradePopupRef);
  const { handleOpen: handleKeepPopupOpen, handleClose: handleKeepPopupClose } =
    useModal(keepPopupRef);
  const {
    handleOpen: cancelDowngradePopupOpen,
    handleClose: cancelDowngradePopupClose,
  } = useModal(cancelDowngradePopupRef);

  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [isCheckoutPopupOpen, setIsCheckoutPopupOpen] = useState(false);
  const [redirectPath, setRedirectPath] = useState(undefined);
  const [courseRedirectPath, setCourseRedirectPath] = useState(undefined);
  const [selectedCourseId, setSelectedCourseId] = useState(undefined);
  const onUnregisteredFreeClick = useCallback(() => {
    setRedirectPath(pathname);
    navigate("/registration");
  }, [navigate, pathname]);

  const onUnregisteredPaidClick = useCallback(
    (plan) => {
      setSelectedSubscription(plan);
      setRedirectPath(pathname);
      navigate("/registration");
    },
    [navigate, pathname]
  );

  const onRegisteredPaidClick = (plan) => {
    setSelectedSubscription(plan);
    setIsCheckoutPopupOpen(true);
  };

  const value = useMemo(
    () => ({
      currentSubscription,
      setCurrentSubscription,
      selectedSubscription,
      setSelectedSubscription,
      cancelPopupRef,
      handleCancelPopupOpen,
      handleCancelPopupClose,
      redirectPath,
      setRedirectPath,
      isCheckoutPopupOpen,
      setIsCheckoutPopupOpen,
      downgradePopupRef,
      handleDowngradePopupOpen,
      handleDowngradePopupClose,
      onUnregisteredFreeClick,
      onUnregisteredPaidClick,
      onRegisteredPaidClick,
      courseRedirectPath,
      setCourseRedirectPath,
      selectedCourseId,
      setSelectedCourseId,
      handleKeepPopupOpen,
      handleKeepPopupClose,
      keepPopupRef,
      cancelDowngradePopupOpen,
      cancelDowngradePopupClose,
      cancelDowngradePopupRef,
    }),
    [
      cancelDowngradePopupClose,
      cancelDowngradePopupOpen,
      courseRedirectPath,
      currentSubscription,
      handleCancelPopupClose,
      handleCancelPopupOpen,
      handleDowngradePopupClose,
      handleDowngradePopupOpen,
      handleKeepPopupClose,
      handleKeepPopupOpen,
      isCheckoutPopupOpen,
      onUnregisteredFreeClick,
      onUnregisteredPaidClick,
      redirectPath,
      selectedCourseId,
      selectedSubscription,
    ]
  );

  return (
    <SubscriptionsContext.Provider value={value}>
      {children}
    </SubscriptionsContext.Provider>
  );
}
