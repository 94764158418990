import React from "react";
import classes from "./index.module.scss";
import { Link } from "react-router-dom";

export default function Button({ icon, label, link }) {
  return (
    <Link to={link} className={classes.button}>
      {icon ? (
        <img
          className={classes.button__icon}
          src={`${process.env.REACT_APP_CMS_DOMAIN}${icon.url}`}
          alt={icon.alternativeText}
          width={icon.width}
          height={icon.height}
        />
      ) : null}
      <span className={classes.button__label}>{label}</span>
    </Link>
  );
}
