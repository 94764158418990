import React from 'react';
import { Box } from '@mui/material';
import classes from './index.module.scss';
import { CarouselButton } from '../../../MUI/buttons/button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { handleNext, handlePrev } from '../../../../helpers/carouselControls';

export default function CustomNavigation({
  sliderRef,
  variant,
}) {

  return (
    <Box className={classes.navigation}>
      <CarouselButton
        variant={variant}
        onClick={(e) => {
          handlePrev(sliderRef);
        }}
      >
        <ChevronLeftIcon />
      </CarouselButton>
      <CarouselButton
        variant={variant}
        onClick={(e) => {
          handleNext(sliderRef);
        }}
      >
        <ChevronRightIcon />
      </CarouselButton>
    </Box>
  );
}
