import Reviews from './Reviews';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useRef } from 'react';
import classes from './SuccessStories.module.scss';
import { WhiteButton } from '../../MUI/buttons/button';
import { api } from '../../../instances/axiosInstances';
import { AuthContext } from '../../../context/AuthContext';
import { Box, Container, Typography } from '@mui/material';
import CustomNavigation from './Reviews/CustomNavigation';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useGTM } from '../../../hooks/useGTM';

export default function SuccessStories(props) {
  const { trackEvent } = useGTM();
  const handleButtonClick = (text) => {
    trackEvent({
      event: "cta_button_click",
      action: "click",
      label: text,
    });
  };
  const promoVariant = props.variant === 'promo';

  const { isAuthLoading, auth } = useContext(AuthContext);
  const authState = auth ? 'registered' : 'unregistered';

  const options = {
    enabled: !isAuthLoading,
  };

  const itemsRes = useQuery(
    ['landing', authState],
    () =>
      api.get(
        auth
          ? process.env.REACT_APP_LANDING_REGISTERED_PATH
          : process.env.REACT_APP_LANDING_UNREGISTERED_PATH
      ),
    options
  );

  const sliderRef = useRef(null);

  const items = itemsRes.isSuccess
    ? itemsRes.data?.data.landing.alumniFeedbacksGeneral
    : [];

  if (!items.length) return null;

  return (
    <Container
      maxWidth="lg"
      id="testimonials"
      className={`${classes.container} ${promoVariant ? classes.promo : ""}`}
    >
      <Box
        className={`${classes.body} ${promoVariant ? classes.promo : ""}`}
        component={"section"}
      >
        <Box className={classes.header}>
          <Typography component={"h2"} variant={"h2"} className={classes.title}>
            {props?.title}
          </Typography>
          <Box className={classes.navigation}>
            <CustomNavigation sliderRef={sliderRef} variant={"contained"} />
          </Box>
        </Box>

        <Box
          className={`${classes.carousel} ${promoVariant ? classes.promo : ""}`}
        >
          <Reviews data={items} sliderRef={sliderRef} />
        </Box>

        <Box className={classes.footer}>
          <Box
            className={`${classes.actions} ${
              promoVariant ? classes.promo : ""
            }`}
          >
            {promoVariant ? (
              props.button
            ) : (
              <WhiteButton
                variant="contained"
                color="secondary"
                component={Link}
                endIcon={<ChevronRightIcon />}
                fullWidth
                to={props?.link?.link}
                onClick={() => handleButtonClick(props?.link?.text)}
              >
                {props?.link?.text}
              </WhiteButton>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
