import CourseForm from "./CourseForm";
import InstructorsForm from "./InstructorsForm";
import ModalHeader from "./ModalHeader";
import classes from "./index.module.scss";
import { Box, Typography } from "@mui/material";

export default function CourseReview({
  isFeedbackComplete,
  userId,
  courseId,
  instructors,
  handleClose,
}) {

  const renderTitle = (isFeedbackComplete) => {
    switch (!!isFeedbackComplete) {
      case true:
        return "Instructor(s) Review";
      default:
        return "Course Review";
    }
  };
  const renderForm = (isFeedbackComplete) => {
    switch (!!isFeedbackComplete) {
      case true:
        return (
          <InstructorsForm
            instructors={instructors}
            courseId={courseId}
            handleClose={handleClose}
            userId={userId}
          />
        );
      default:
        return (
          <CourseForm
            courseId={courseId}
            handleClose={handleClose}
            userId={userId}
          />
        );
    }
  };
  return (
    <Box className={classes.wrapper}>
      <ModalHeader
        title={renderTitle(isFeedbackComplete)}
        handleClose={handleClose}
      />
      <Typography className={classes.text}>
        Congratulations on completing the course! Before issuing a certificate,
        please dedicate 2 minutes on the course review
      </Typography>
      {renderForm(isFeedbackComplete)}
    </Box>
  );
}
