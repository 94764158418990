import React from "react";
import classes from "./index.module.scss";
import Container from "../common/Container";
import SectionTitle from "../common/SectionTitle";
import BlockTitle from "../common/BlockTitle";

export default function WhyUs(props) {
  const { title, traditional_list, our_list } = props;
  return (
    <section>
      <Container>
        <div className={classes.wrapper}>
          <SectionTitle className={classes.title}>{title}</SectionTitle>
          <div className={classes.content}>
            {traditional_list ? (
              <div className={classes.card}>
                {traditional_list.title ? (
                  <BlockTitle className={classes.card__title}>
                    {traditional_list.title}
                  </BlockTitle>
                ) : null}
                <ul className={classes.list}>
                  {traditional_list.list_item.map((item) => (
                    <li key={item.id} className={classes.list__item}>
                      <img
                        src={`${process.env.REACT_APP_CMS_DOMAIN}${traditional_list.icon.url}`}
                        alt={traditional_list.icon.alternativeText}
                        width={traditional_list.icon.width}
                        height={traditional_list.icon.height}
                        className={classes.list__icon}
                      />
                      <span className={classes.list__text}>{item.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {our_list ? (
              <div className={classes.card}>
                {our_list.title ? (
                  <BlockTitle className={classes.card__title}>
                    {our_list.title}
                  </BlockTitle>
                ) : null}
                <ul className={classes.list}>
                  {our_list.list_item.map((item) => (
                    <li key={item.id} className={classes.list__item}>
                      <img
                        src={`${process.env.REACT_APP_CMS_DOMAIN}${our_list.icon.url}`}
                        alt={our_list.icon.alternativeText}
                        width={our_list.icon.width}
                        height={our_list.icon.height}
                        className={classes.list__icon}
                      />
                      <span className={classes.list__text}>{item.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </Container>
    </section>
  );
}
