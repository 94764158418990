import { useQuery } from "@tanstack/react-query";
import React from "react";
import { api } from "../../../instances/axiosInstances";
import Loader from "../../../components/Common/Loader";
import CoursesList from "./CoursesList";

export default function MyCourses() {
  const { data, isLoading } = useQuery(["my-courses", "registered"], () =>
    api.get("/courses/user/list")
  );

  const courses = data?.data.courses;
  return (
    <>
      {isLoading ? <Loader /> : null}
      <CoursesList
        isLoading={isLoading}
        courses={courses}
        isUserCourses={true}
      />
    </>
  );
}
