import { Box, Typography } from "@mui/material";
import { Accordion, Summary, Details } from "../Accordion/ModuleAccordion";
import { useParams } from "react-router-dom";
import useAccordion from "../../../../hooks/useAccordion";
import Tasks from "../Tasks";
import ModuleDetails from "./ModuleDetails";
import ModuleSummary from "./ModuleSummary";
import useExpandedIndex from "./hooks/useExpandedIndex";
import { getLecturesDurationAndCount } from "./helpers/getLecturesDuration";
import Progress from "../../../../components/Common/Progress/Progress";
import classes from "./index.module.scss";
import BackLink from "../../../../components/Common/BackLink";
import AdditionalResources from "./AdditionalResources";

export default function Modules({ course, setValue }) {
  const { lectureId, courseSlug } = useParams();

  const { expandedIndex } = useExpandedIndex({ lectureId, course });
  const { expanded, handleChange } = useAccordion(`panel${expandedIndex}`);
  return (
    <Box component={"aside"} className={classes.sidebar}>
      <Box className={classes.back}>
        <BackLink
          path={`/courses/${courseSlug}`}
          text="Back to Course Page"
          isLogoVisible={false}
        />
      </Box>
      <Typography component="h1" variant="h1" className={classes.title}>
        {course?.data.courses.title}
      </Typography>
      {course?.data.courses?.isPaid ? (
        <Box className={classes.progress}>
          <Progress course={course?.data.courses} />
        </Box>
      ) : null}
      <Tasks tasks={course?.data.courses?.preparationTasks} />
      <Box className={classes.modules}>
        {course?.data.courses.modules.map((module) => {
          const { lecturesCount, lecturesDuration } =
            getLecturesDurationAndCount(module);
          return (
            <Box className={classes.module} key={module.id}>
              <Accordion
                expanded={expanded}
                handleChange={handleChange}
                index={module.id}
              >
                <Summary index={module.id}>
                  <ModuleSummary
                    lecturesCount={lecturesCount}
                    lecturesDuration={lecturesDuration}
                    title={module.title}
                  />
                </Summary>
                <Details>
                  <ModuleDetails
                    course={course}
                    module={module}
                    setValue={setValue}
                  />
                  <AdditionalResources
                    resources={module?.additionalResources}
                    isPaid={course?.data.courses?.isPaid}
                  />
                </Details>
              </Accordion>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
