import React from 'react'
import classes from "./index.module.scss";
import Container from '../common/Container';
import SectionTitle from '../common/SectionTitle';
import ProgressiveImage from '../common/ProgressiveImage';
import BlockTitle from '../common/BlockTitle';
import HighlightedText from '../common/HighlightedText';

export default function Mentors(props) {
const { title, card } = props;
return (
  <section>
    <Container>
      <div className={classes.wrapper}>
        <SectionTitle className={classes.title}>{title}</SectionTitle>
        <ul className={classes.list}>
          {card.map((item) => (
            <li key={item.id} className={classes.card}>
                <ProgressiveImage
                  src={`${process.env.REACT_APP_CMS_DOMAIN}${item.image.url}`}
                  placeholderSrc={`${process.env.REACT_APP_CMS_DOMAIN}${item.image.formats.thumbnail.url}`}
                  alt={item.image.alternativeText}
                  width={item.image.width}
                  height={item.image.height}
                  containerClassName={classes.card__image}
                />
                <BlockTitle className={classes.card__title}>
                  {item.name}
                </BlockTitle>
                <div className={classes.card__text}>
                  <HighlightedText
                    text={item.bio}
                    highlightedClassName={classes.card__text_highlighted}
                  />
                </div>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  </section>
);}

