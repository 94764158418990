import { NavLink, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { formatTimeMilliseconds } from "../../../../helpers/formatTime";
import classes from "./index.module.scss";

export default function ModuleDetails({ course, module, setValue }) {
  const {courseSlug} = useParams()
  return (
    <table className={classes.table}>
      <tbody>
        {module.lectures.map((lecture) => {
          return (
            <tr key={lecture.id} className={classes.row}>
              <td className={classes.icon}>
                {lecture?.userLecture?.isComplete ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9.99997 15.586L6.70697 12.293L5.29297 13.707L9.99997 18.414L19.707 8.70703L18.293 7.29303L9.99997 15.586Z"
                      fill="#2BAD28"
                    />
                  </svg>
                ) : null}
                {!lecture?.isFree && !course?.data.courses.isPaid ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5.99945 22.0011C5.44945 22.0011 4.97845 21.8051 4.58645 21.4131C4.19445 21.0211 3.99879 20.5504 3.99945 20.0011V10.0011C3.99945 9.4511 4.19545 8.9801 4.58745 8.5881C4.97945 8.1961 5.45012 8.00043 5.99945 8.0011H6.99945V6.0011C6.99945 4.61777 7.48712 3.43843 8.46245 2.4631C9.43779 1.48777 10.6168 1.00043 11.9995 1.0011C13.3828 1.0011 14.5621 1.48877 15.5375 2.4641C16.5128 3.43943 17.0001 4.61843 16.9995 6.0011V8.0011H17.9995C18.5495 8.0011 19.0205 8.1971 19.4125 8.5891C19.8045 8.9811 20.0001 9.45177 19.9995 10.0011V20.0011C19.9995 20.5511 19.8035 21.0221 19.4115 21.4141C19.0195 21.8061 18.5488 22.0018 17.9995 22.0011H5.99945ZM11.9995 17.0011C12.5495 17.0011 13.0205 16.8051 13.4125 16.4131C13.8045 16.0211 14.0001 15.5504 13.9995 15.0011C13.9995 14.4511 13.8035 13.9801 13.4115 13.5881C13.0195 13.1961 12.5488 13.0004 11.9995 13.0011C11.4495 13.0011 10.9785 13.1971 10.5865 13.5891C10.1945 13.9811 9.99879 14.4518 9.99945 15.0011C9.99945 15.5511 10.1955 16.0221 10.5875 16.4141C10.9795 16.8061 11.4501 17.0018 11.9995 17.0011ZM8.99945 8.0011H14.9995V6.0011C14.9995 5.16777 14.7078 4.45943 14.1245 3.8761C13.5411 3.29277 12.8328 3.0011 11.9995 3.0011C11.1661 3.0011 10.4578 3.29277 9.87445 3.8761C9.29112 4.45943 8.99945 5.16777 8.99945 6.0011V8.0011Z"
                      fill="#3D40D5"
                      fillOpacity="0.2"
                    />
                  </svg>
                ) : null}
              </td>
              <td className={classes.link}>
                <Typography
                  component={NavLink}
                  to={`/courses/${courseSlug}/lecture/${lecture.id}`}
                  onClick={() => (setValue ? setValue(0) : false)}
                >
                  {lecture.title}
                </Typography>
                <Typography>
                  {formatTimeMilliseconds(lecture.duration)}
                </Typography>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
