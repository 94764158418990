import Rating from "../../Rating";
import { Link } from "react-router-dom";
import classes from "./index.module.scss";
import { formatCurrency } from "../../../../helpers/formatCurrency";

export default function CourseCard({ course, onClick }) {
  const ratingOptions = {
    rating: Number(course.rating),
    votes: course.reviewsCount || 0,
    stars: Number(course.rating),
    readOnly: true,
  };

  const COURSE_TYPES = {
    live: "live",
    recorded: "recorded",
  };

  const renderLevel = (level) => {
    switch (level) {
      case 0:
        return "Basic";
      case 1:
        return "Intermediate";
      case 2:
        return "Advanced";
      default:
        return null;
    }
  };

  function formatDateToPST(dateString) {
    const date = new Date(dateString);

    const options = {
      timeZone: "America/Los_Angeles",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  return (
    <Link
      to={`/courses/${course.slug}`}
      className={classes.card}
      onClick={() => onClick(course)}
    >
      <div className={classes.image}>
        <img src={course.promoImageUrl} alt={course.title} />
      </div>
      <div className={classes.info}>
        <div className={classes.header}>
          <div className={classes.tags}>
            {course.type === "recorded" ? (
              <div className={`${classes.tag} ${classes.recorded}`}>
                Self-paced
              </div>
            ) : null}
            {course.type === "live" ? (
              <div className={`${classes.tag} ${classes.live}`}>Live</div>
            ) : null}
            <div className={classes.tag}>
              {renderLevel(course.difficultyLevel)}
            </div>
          </div>
          <h3 className={classes.title}>{course.title}</h3>
        </div>
        {course?.type === COURSE_TYPES.recorded && (
          <div className={classes.card__footer}>
            <Rating options={ratingOptions} aria-label="course rating" />
          </div>
        )}
        {course?.type === COURSE_TYPES.live && course?.isComplete && (
          <div className={classes.card__footer}>
            <span className={classes.card__footer_heading}>
              {course.isPaid ? "Completed" : "Finished"}
            </span>
            <Rating options={ratingOptions} aria-label="course rating" />
          </div>
        )}
        {course?.type === COURSE_TYPES.live && !course?.isComplete && (
          <div className={classes.card__footer}>
            {course?.liveSchedule && course.liveSchedule[0] ? (
              <div>
                <span className={classes.card__footer_heading}>Start: </span>
                <span className={classes.card__footer_text}>
                  {formatDateToPST(course.liveSchedule[0].from)}
                </span>
              </div>
            ) : null}
            <div>
              <span className={classes.card__footer_heading}>Price: </span>
              <span className={classes.card__footer_text}>
                {formatCurrency(course?.price)}
              </span>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
}
