import { Box, Typography } from "@mui/material";
import { ReactComponent as Avatar } from "../../../../../assets/icons/avatar.svg";
import ShowMore from "../../../../../components/Common/ShowMore";
import SectionText from "../../components/SectionText";
import HTMLComponent from "../../../../../components/Common/HTMLComponent";
import { Link, useParams } from "react-router-dom";

import classes from "./index.module.scss";
export default function Slide({ instructor }) {
  const { courseSlug } = useParams();
  return (
    <Box className={classes.card}>
      <Box className={classes.card__header}>
        <Box className={classes.image}>
          {instructor?.photoUrl ? (
            <img
              alt={instructor?.fullName || "Instructor"}
              src={instructor?.photoUrl}
            />
          ) : (
            <Avatar />
          )}
        </Box>
        <Box className={classes.info}>
          {instructor?.fullName ? (
            <Typography
              component={Link}
              to={`/courses/${courseSlug}/instructor/${instructor?.id}`}
              className={classes.title}
            >
              {instructor.fullName}
            </Typography>
          ) : null}
          {instructor?.position ? (
            <Typography className={classes.subtitle}>
              {instructor.position}
            </Typography>
          ) : null}
        </Box>
      </Box>
      <Box>
        {instructor.introductionText ? (
          <ShowMore maxHeight={120}>
            {instructor.introductionText.toString().includes("</") ? (
              <HTMLComponent html={instructor.introductionText} />
            ) : (
              <SectionText>{instructor.introductionText}</SectionText>
            )}
          </ShowMore>
        ) : null}
      </Box>
    </Box>
  );
}
