import React from "react";

export default function HighlightedText({
  text,
  className = "",
  highlightedClassName = "",
}) {
  function splitText(text) {
    const regex = /\[.*?\]|\S+/g;
    const result = text.match(regex);

    return result?.map((item) => {
      if (item.startsWith("[") && item.endsWith("]")) {
        return item;
      }
      return item;
    });
  }
  const tempText = splitText(text);
  let result = [];

  tempText?.forEach((word, index) => {
    if (word.startsWith("[") && word.endsWith("]")) {
      const highlight = word.replace("[", "").replace("]", "");
      result.push(
        `<span key=${index} class="${
          highlightedClassName ? highlightedClassName : ""
        }">${highlight}</span> `
      );
    } else result.push(word + " ");
  });

  return (
    <span
      className={`${className}`.trim()}
      dangerouslySetInnerHTML={{
        __html: result
          .join("")
          .replace(/\s+\./g, ".")
          .replace(/\s+\,/g, ",")
          .replace(/\s+\!/g, "!")
          .replace(/\s+\?/g, "?"),
      }}
    />
  );
}
