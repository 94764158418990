import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { apiCms } from "../../../../../instances/axiosInstances";
import { promoCourseQuery } from "./query";
import Loader from "../../../../../components/Common/Loader";
import { LandingLayout } from "../../../../../components/Layouts";
import Hero from "../components/Hero";
import Description from "../components/Description";
import ForAnswersAndQuestions from "../components/ForAnswersAndQuestions";
import ForWho from "../components/ForWho";
import Mentors from "../components/Mentors";
import Program from "../components/Program";
import Reminder from "../components/Reminder";
import WhyUs from "../components/WhyUs";
import classes from "./index.module.scss";

const componentsMap = {
  Hero,
  Description,
  ForAnswersAndQuestions,
  ForWho,
  Mentors,
  Program,
  Reminder,
  WhyUs,
  // Add additional components here
};

export default function Course() {
  const { courseSlug } = useParams();

  const { data: promoCourseData, isLoading } = useQuery(
    ["promoCourseData", courseSlug],
    () =>
      apiCms.get(
        `/promo-courses?${promoCourseQuery}&filters[slug][$eq]=${encodeURIComponent(
          courseSlug
        )}`
      )
  );

  if (isLoading) return <Loader />;

  const courseData = promoCourseData?.data.data[0];
  const sections = courseData?.sections;

  return (
    <LandingLayout>
      <div className={classes.wrapper}>
        {sections.map((section, index) => {
          const componentName = section.__component
            .split(".")[1]
            .split("-")
            .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
            .join("");
          const Component = componentsMap[componentName];

          if (!Component) {
            console.warn(`Component "${componentName}" not found!`);
            return null;
          }

          return <Component key={`${section.id}-${index}`} {...section} />;
        })}
      </div>
    </LandingLayout>
  );
}
