import { Link } from "react-router-dom";
import classes from "./Navbar.module.scss";
import { links } from "./links";
import { useGTM } from "../../../../../hooks/useGTM";

export default function Navbar({ className = "", variant }) {
  const { trackEvent } = useGTM();
  const handleTrackNavLinkClick = (label) => {
    trackEvent({
      event: "header_navigation_link_click",
      action: "click",
      label,
    });
  };
  return (
    <nav className={`${classes.root} ${className}`}>
      {links.map(({ label, path }, index) => {
        return (
          <Link
            key={index}
            className={`${classes.link} ${classes[variant]}`}
            to={path}
            onClick={() => handleTrackNavLinkClick(label)}
          >
            {label}
          </Link>
        );
      })}
    </nav>
  );
}
