import { useContext, useEffect } from "react";
import ImageUpload from "./ImageUpload";
import QuestionnaireFilled from "./QuestionnaireFilled";
import { useQueryClient } from "@tanstack/react-query";
import SettingsLayout from "../../components/Layouts/SettingsLayout";
import SectionCard from "../../components/Common/SectionCard";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../../components/Common/Loader";

export default function Profile() {
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const data = queryClient.getQueryData(["user", user?.id]);
  const state = queryClient.getQueryState(["user", user?.id]);

  useEffect(() => {
    localStorage.removeItem("redirectPath");
  }, []);

  if (state?.status === "loading") {
    return <Loader />;
  }

  return (
    <SettingsLayout title="Profile">
      <SectionCard>
        <QuestionnaireFilled
          user={data.data.users}
          isUserLoading={state.status === "loading"}
        />
      </SectionCard>

      <SectionCard title="Profile picture">
        <ImageUpload
          profilePhotoUrl={data?.data.users.profilePhotoUrl}
          userId={data?.data.users.id}
        />
      </SectionCard>
    </SettingsLayout>
  );
}
