import { ReactComponent as CoursesIcon } from "./images/courses.svg";
import { ReactComponent as PathsIcon } from "./images/paths.svg";
import { ReactComponent as RatingIcon } from "./images/rating.svg";
import { ReactComponent as MentorshipIcon } from "./images/mentorship.svg";
import { ReactComponent as EventsIcon } from "./images/events.svg";
import { ReactComponent as BlogIcon } from "./images/blog.svg";
import { ReactComponent as ProfileIcon } from "./images/profile.svg";

import { ReactComponent as FacebookIcon } from "../../../../../assets/images/facebook.svg";
import { ReactComponent as YoutubeIcon } from "../../../../../assets/images/youtube-white.svg";
import { ReactComponent as DiscordIcon } from "../../../../../assets/images/discord-white.svg";

export const menuItems = [
  {
    icon: <CoursesIcon />,
    name: "Courses",
    link: "/courses",
    reqAuth: false,
    externalLink: false,
  },
  {
    icon: <PathsIcon />,
    name: "Paths",
    link: "/paths",
    reqAuth: false,
    externalLink: false,
  },
  {
    icon: <RatingIcon />,
    name: "Rating",
    link: "/rating",
    reqAuth: false,
    externalLink: false,
  },
  {
    icon: <ProfileIcon />,
    name: "Profile",
    link: "/profile",
    reqAuth: true,
    externalLink: false,
  },
  {
    divider: true,
  },
  {
    icon: <MentorshipIcon />,
    name: "Mentorship",
    link: "/mentorship",
    reqAuth: false,
    externalLink: false,
  },
  {
    icon: <EventsIcon />,
    name: "Events",
    link: "/events",
    reqAuth: false,
    externalLink: false,
  },
  {
    icon: <BlogIcon />,
    name: "Blog",
    link: "/posts",
    reqAuth: false,
    externalLink: false,
  },
];

export const mediaItems = [
  {
    icon: <FacebookIcon />,
    link: process.env.REACT_APP_FACEBOOK_LINK,
    label:"facebook"
  },
  {
    icon: <YoutubeIcon />,
    link: process.env.REACT_APP_YOUTUBE_LINK,
    label:"youtube"
  },
  {
    icon: <DiscordIcon />,
    link: process.env.REACT_APP_DISCORD_LINK,
    label:"discord"
  },
];
