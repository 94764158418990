import React from "react";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import CoursesList from "./CoursesList";
import Loader from "../../../components/Common/Loader";

export default function AllCourses({ isAuthenticated, user }) {

  const isAdmin = user?.role === 'admin'

  const COURSES_API_URL = isAuthenticated
    ? process.env.REACT_APP_COURSES_REGISTERED_PATH
    : process.env.REACT_APP_COURSES_UNREGISTERED_PATH;

  const { data, isLoading } = useQuery(
    ["all-courses", isAuthenticated ? "registered" : "unregistered"],
    () => api.get(COURSES_API_URL)
  );

  const courses = isAdmin ? data?.data.courses.courses : data?.data.courses;

  return (
    <>
      {isLoading ? <Loader /> : null}
      <CoursesList isLoading={isLoading} courses={courses} isUserCourses={false} />
    </>
  );
}
