import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import { Input } from "../../../components/MUI/inputs";
import { Box } from "@mui/system";
import SocialAuth from "../../../components/Common/SocialAuth";
import { Divider } from "../../../components/MUI/dividers";
import errorToast from "../../../components/toasts/errorToast";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../../constants/regex";
import { IconButton, InputAdornment } from "@mui/material";
import useShowPassword from "../../../hooks/useShowPassword";
import ShowPasswordIcon from "../../../components/Common/ShowPasswordIcon";
import {
  AccentButton,
  WhiteButton,
} from "../../../components/MUI/buttons/button";
import Navigation from "../../../components/Layouts/AuthLayout/Navigation";
import BackLink from "../../../components/Common/BackLink";
import classes from "./index.module.scss";
import { parseJwt } from "../../../helpers/parseJwt";

const schema = yup.object().shape({
  email: yup
    .string()
    .matches(EMAIL_REGEX, "Invalid email address")
    .email()
    .required(),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      PASSWORD_REGEX,
      "Your password should have minimum eight characters, at least one uppercase letter, one lowercase letter and one digit"
    ),
});

const Login = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const { setAuthUser } = useContext(AuthContext);

  const { handleClickShowPassword, showPassword } = useShowPassword();

  const { mutate, isLoading } = useMutation(
    (data) => api.post("/auth/login", data),
    {
      onSuccess: ({ data }) => {
        const user = parseJwt(data.accessToken);

        if (user.isQuestionnaireComplete) {
          setAuthUser(data);
        } else {
          localStorage.setItem("accessToken", data.accessToken);
          localStorage.setItem("createProfileToken", data.refreshToken);
          localStorage.setItem("sub", user.sub);
          navigate("/registration/create-profile");
        }
        window.dataLayer.push({
          event: "sign_in",
          type: "default",
        });
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <Box className={classes.wrapper}>
      <BackLink />
      <Navigation />
      <SocialAuth />
      <Divider>Or use email</Divider>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Email"
          type="email"
          margin="normal"
          variant="outlined"
          fullWidth
          autoComplete="user-email"
          disabled={isLoading}
          InputLabelProps={{
            shrink: true,
            disableAnimation: true,
          }}
          placeholder="Email"
          {...register("email")}
          error={!!errors.email}
          helperText={errors.email && errors.email.message}
        />
        <Input
          label="Password"
          margin="normal"
          variant="outlined"
          fullWidth
          autoComplete="current-password"
          disabled={isLoading}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  <ShowPasswordIcon showPassword={showPassword} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
            disableAnimation: true,
          }}
          placeholder="Your password"
          {...register("password")}
          error={!!errors.password}
          helperText={errors.password && errors.password.message}
        />

        <Box className={classes.actions}>
          <AccentButton
            isBig={false}
            className={classes.button}
            variant="contained"
            type="submit"
            disabled={isLoading}
            fullWidth
          >
            {isLoading ? "Loading..." : "Login"}
          </AccentButton>
          <WhiteButton
            isBig={false}
            variant="contained"
            to="/forgot-password"
            disabled={isLoading}
            fullWidth
            component={Link}
          >
            Forgot password
          </WhiteButton>
        </Box>
      </form>
    </Box>
  );
};

export default Login;
