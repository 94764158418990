import { Box } from "@mui/material";
import { AccentButton, WhiteButton } from "../../MUI/buttons/button";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import errorToast from "../../toasts/errorToast";
import { api } from "../../../instances/axiosInstances";
import Rating from "./Rating";
import Checkbox from "./Checkbox";
import Textarea from "./Textarea";
import FormWrapper from "./FormWrapper";
import classes from "./index.module.scss";
import { useParams } from "react-router-dom";

const schema = yup.object().shape({
  course: yup.number().required(),
  rating: yup
    .number()
    .min(0.1, "Rating is required")
    .required("Rating is required"),
  text: yup.string(),
  isVideoFeedbackAccepted: yup.boolean(),
});

export default function CourseForm({ courseId, handleClose, userId }) {
  const defaultValues = {
    course: Number(courseId),
    rating: 0,
    text: "",
    isVideoFeedbackAccepted: false,
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const queryClient = useQueryClient();
  const {  courseSlug } = useParams();

  const {
    mutate: courseReviewMutation,
    isLoading: isCourseReviewMutationLoading,
  } = useMutation((data) => api.post(`/alumniFeedbacks/platform`, data), {
    onSuccess: async () => {

      await queryClient.invalidateQueries({
        queryKey: ["course", "registered", courseSlug],
        refetchType: "all",
        exact: true,
      });
    },
    onError: (error) => {
      errorToast(error.response.data.message);
    },
  });

  const onSubmit = async (data) => {
    courseReviewMutation(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper>
        <Rating
          Controller={Controller}
          control={control}
          errors={errors}
          name="rating"
        />
        <Textarea
          Controller={Controller}
          control={control}
          errors={errors}
          name={"text"}
        />
        <Checkbox
          Controller={Controller}
          control={control}
          errors={errors}
          name="isVideoFeedbackAccepted"
        />
        <Box className={classes.actions}>
          <WhiteButton isBig={false} variant="contained" onClick={handleClose}>
            Cancel
          </WhiteButton>
          <AccentButton
            isBig={false}
            variant="contained"
            type="submit"
            disabled={isCourseReviewMutationLoading}
          >
            Next
          </AccentButton>
        </Box>
      </FormWrapper>
    </form>
  );
}
