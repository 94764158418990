import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import CourseCard from "../../../../../components/MUI/cards/CourseCard";
import classes from "./index.module.scss";
import { useEffect, useRef, useState } from "react";
import CustomNavigation from "./CustomNavigation";
import { useGTM } from "../../../../../hooks/useGTM";

SwiperCore.use([Autoplay]);

export default function Carousel({ data }) {
  const sliderRef = useRef();
  const wrapperRef = useRef();
  const [isSliderDisabled, setIsSliderDisabled] = useState(true);
  const [slides, setSlides] = useState([]);
  const [key, setKey] = useState(Date.now());
  const { trackEvent } = useGTM();
  const handleCourseClick = (course) => {
    trackEvent({
      event: "featured_course_click",
      action: "click",
      course: {
        id: course.id,
        title: course.title,
        type: course.type,
      },
    });
  };

  useEffect(() => {
    const checkSliderCondition = () => {
      if (!wrapperRef.current || !sliderRef.current) return;
      const swiperContainerWidth = wrapperRef.current.offsetWidth;
      const slidesArray = Array.from(
        sliderRef.current.querySelectorAll(".swiper-slide__courses")
      ).slice(0, 4);
      const gap = window.innerWidth >= 768 ? 24 : 16;
      const totalSlidesWidth =
        slidesArray.reduce((acc, slide) => acc + slide.offsetWidth, 0) +
        (slidesArray.length - 1) * gap;

      setIsSliderDisabled(totalSlidesWidth <= swiperContainerWidth);
    };

    checkSliderCondition();

    window.addEventListener("resize", checkSliderCondition);
    return () => window.removeEventListener("resize", checkSliderCondition);
  }, [slides]);

  useEffect(() => {
    isSliderDisabled
      ? setSlides([...data].slice(0, 4))
      : setSlides(Array(2).fill(slides).flat());
    setKey(Date.now());
  }, [isSliderDisabled]);

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      <Swiper
        key={key}
        slidesPerView={"auto"}
        spaceBetween={16}
        ref={sliderRef}
        className={classes.swiper}
        loop={true}
        speed={2000}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          768: {
            spaceBetween: 24,
          },
        }}
      >
        {slides.map((course, index) => {
          return (
            <SwiperSlide
              key={`${course.id}-${index}`}
              className={`${classes.slide} swiper-slide__courses`}
            >
              <CourseCard
                course={course}
                onClick={handleCourseClick}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>

      {isSliderDisabled ? null : <CustomNavigation sliderRef={sliderRef} />}
    </div>
  );
}
