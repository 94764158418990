import React from "react";
import classes from "./index.module.scss";
import Container from "../common/Container";
import SectionTitle from "../common/SectionTitle";
import RegularPrice from "../common/RegularPrice";
import HighlightedText from "../common/HighlightedText";
import CurrentPrice from "../common/CurrentPrice";
import Button from "../common/Button";
import ProgressiveImage from "../common/ProgressiveImage";

export default function Program(props) {
  const { title, regular_price, current_price, link, image, list } = props;
  return (
    <section>
      <Container>
        <div className={classes.wrapper}>
          <div className={classes.main}>
            <div className={classes.media}>
              <ProgressiveImage
                src={`${process.env.REACT_APP_CMS_DOMAIN}${image.url}`}
                placeholderSrc={`${process.env.REACT_APP_CMS_DOMAIN}${image.formats.thumbnail.url}`}
                alt={image.alternativeText}
                width={image.width}
                height={image.height}
                containerClassName={classes.image}
              />
            </div>
            <div className={classes.content}>
              <SectionTitle className={classes.title}>{title}</SectionTitle>
              <ul className={classes.list}>
                {list.list_item.map((item) => (
                  <li key={item.id} className={classes.list__item}>
                    <img
                      src={`${process.env.REACT_APP_CMS_DOMAIN}${list.icon.url}`}
                      alt={list.icon.alternativeText}
                      width={list.icon.width}
                      height={list.icon.height}
                      className={classes.list__icon}
                    />
                    <span className={classes.list__text}>{item.text}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={classes.footer}>
            {regular_price || current_price ? (
              <div className={classes.price}>
                {current_price ? (
                  <CurrentPrice>
                    <HighlightedText
                      text={current_price.price}
                      highlightedClassName={classes.price__current_highlighted}
                    />
                  </CurrentPrice>
                ) : null}
                {regular_price ? (
                  <RegularPrice>
                    <HighlightedText
                      text={regular_price.price}
                      highlightedClassName={classes.price__regular_highlighted}
                    />
                  </RegularPrice>
                ) : null}
              </div>
            ) : null}
            {link ? (
              <Button link={link.link} label={link.text} icon={link.icon} />
            ) : null}
          </div>
        </div>
      </Container>
    </section>
  );
}
