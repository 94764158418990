import React from "react";
import classes from "./index.module.scss";
import Container from "../common/Container";
import SectionTitle from "../common/SectionTitle";
import ProgressiveImage from "../common/ProgressiveImage";

export default function ForWho(props) {
  const { title, image, list } = props;
  return (
    <section>
      <Container>
        <div className={classes.main}>
          <div className={classes.content}>
            <SectionTitle className={classes.title}>{title}</SectionTitle>
            <ul className={classes.list}>
              {list.list_item.map((item) => (
                <li key={item.id} className={classes.list__item}>
                  {item.text}
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.media}>
            <ProgressiveImage
              src={`${process.env.REACT_APP_CMS_DOMAIN}${image.url}`}
              placeholderSrc={`${process.env.REACT_APP_CMS_DOMAIN}${image.formats.thumbnail.url}`}
              alt={image.alternativeText}
              width={image.width}
              height={image.height}
              containerClassName={classes.image}
            />
          </div>
        </div>
      </Container>
    </section>
  );
}
