import { Box, Typography } from "@mui/material";
import ModalHeader from "./ModalHeader";
import classes from "./index.module.scss";
import { RedButton, WhiteButton } from "../../../MUI/buttons/button";
import successToast from "../../../toasts/successToast";
import errorToast from "../../../toasts/errorToast";
import { useKeepSubscriptionApi } from "../../../../apis/payments";
import { useQueryClient } from "@tanstack/react-query";
import { useGTM } from "../../../../hooks/useGTM";

export default function KeepSubscriptionModal({ handlePopupClose }) {
  const queryClient = useQueryClient();
  const { trackEvent } = useGTM();
  const successToastId = "success-toast";
  const errorToastId = "error-toast";

  const closeButtonLabel = "Close";
  const keepPlanButtonLabel = "Keep plan";

  const handleKeepPlanClick = () => {
    trackEvent({
      event: "keep_plan_button_click",
      action: "click",
      label: keepPlanButtonLabel,
    });
    keepSubscriptionApi.mutate();
  };

  const handleCloseClick = () => {
    trackEvent({
      event: "keep_close_click",
      action: "click",
      label: closeButtonLabel,
    });
    handlePopupClose();
  };

  const keepSubscriptionApi = useKeepSubscriptionApi({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["plans"],
        refetchType: "all",
      });
      await queryClient.invalidateQueries({
        queryKey: ["currentSubscription"],
        refetchType: "all",
      });
      handlePopupClose();
      successToast(
        "Subscription canceled. Plan active until next billing period",
        {
          toastId: successToastId,
        }
      );
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message, {
        toastId: errorToastId,
        autoClose: false,
        hideProgressBar: true,
      });
    },
  });

  return (
    <Box className={classes.wrapper}>
      <ModalHeader
        title={"Keep subscription?"}
        handleClose={handlePopupClose}
      />
      <Typography className={classes.text}>
        No additional charges will be made. You will remain on your current
        plan.
      </Typography>
      <Box className={classes.actions}>
        <WhiteButton
          onClick={handleCloseClick}
          isBig={false}
          variant="contained"
          disabled={keepSubscriptionApi.isLoading}
        >
          {closeButtonLabel}
        </WhiteButton>
        <RedButton
          onClick={handleKeepPlanClick}
          isBig={false}
          variant="contained"
          disabled={keepSubscriptionApi.isLoading}
        >
          {keepPlanButtonLabel}
        </RedButton>
      </Box>
    </Box>
  );
}
