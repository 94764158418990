import { Box, Container } from "@mui/material";
import { ReactComponent as DesktopIcon } from "./images/icon-desktop.svg";
import { ReactComponent as MobileIcon } from "./images/icon-mobile.svg";
import { ReactComponent as CheckMark } from "./images/check.svg";
import { ReactComponent as VideoWrapper } from "./images/video-wrapper.svg";
import classes from "./index.module.scss";
import { WhiteButton } from "../../../../components/MUI/buttons/button";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { useEffect, useState } from "react";
import { useGTM } from "../../../../hooks/useGTM";

export default function Hero(props) {
  const [ready, setReady] = useState(false);
  const { trackEvent } = useGTM()
  const handleButtonClick = (text) => {
    trackEvent({
      event: "cta_button_click",
      action: "click",
      label: text,
    });
  };
  useEffect(() => {
    return () => {
      setReady(false);
    };
  }, []);

  if (!props) return null;

  return (
    <Box className={classes.section} component="section">
      <Container maxWidth="lg">
        <Box className={classes.content}>
          <Box className={classes.info}>
            <div className={classes.icon}>
              <DesktopIcon className={classes.icon__desktop} />
              <MobileIcon className={classes.icon__mobile} />
            </div>
            <h1 className={classes.title}>{props.title}</h1>
            <ul className={classes.list}>
              {props.list.map((item) => (
                <li className={classes.list__item} key={item.id}>
                  <span className={classes.list__check}>
                    <CheckMark />
                  </span>
                  <span className={classes.list__text}>{item.text}</span>
                </li>
              ))}
            </ul>
            <p className={classes.description}>{props.description}</p>
            <WhiteButton
              isBig
              variant="contained"
              component={Link}
              to={props.button.link}
              className={classes.button}
              onClick={() => handleButtonClick(props.button.text)}
            >
              {props.button.text}
            </WhiteButton>
          </Box>
          <Box className={classes.media}>
            <VideoWrapper />

            <div className={classes.player}>
              {!ready ? (
                <img
                  src={`${process.env.REACT_APP_CMS_DOMAIN}${props.videoPoster.url}`}
                  alt={props.title}
                  className={classes.poster}
                  loading="eager"
                />
              ) : null}
              <ReactPlayer
                className={classes.video}
                url={props.videoLink}
                loop
                muted
                controls
                playing
                width={"100%"}
                height={"100%"}
                onPlay={() => setReady(true)}
              />
            </div>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
