import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/system";
import errorToast from "../../../components/toasts/errorToast";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { AccentButton } from "../../../components/MUI/buttons/button";
import classes from "./index.module.scss";
import { schema } from "./schema";

const steps = [
  { label: "Step 1", component: Step1 },
  { label: "Step 2", component: Step2 },
];

const CreatePassword = () => {
  const {
    trigger,
    register,
    handleSubmit,
    resetField,
    clearErrors,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { setAuthUser, setAccessToken } = useContext(AuthContext);
  const password = useRef({});
  password.current = watch("password", "");
  const [step, setStep] = useState(0);
  const queryClient = useQueryClient();

  const setCurrentPath = (provider) => {
    switch (provider) {
      case "google":
        return "/auth/google";
      case "discord":
        return "/auth/discord";
      case "facebook":
        return "/auth/facebook";
      default:
        return "/auth/register";
    }
  };

  const StepComponent = steps[step].component;

  const onNextStep = async () => {
    const stepFields = getStepFields(step);
    trigger(stepFields).then((data) =>
      data ? setStep((prevStep) => prevStep + 1) : false
    );
  };

  const onPrevStep = (e) => {
    e.preventDefault();
    setStep((prevStep) => prevStep - 1);
  };

  const getStepFields = (step) => {
    switch (step) {
      case 0:
        return [
          "firstName",
          "lastName",
          "phoneNumber",
          "password",
          "confirmPassword",
        ];
      default:
        return [];
    }
  };

  const [searchParams] = useSearchParams();

  const { mutate: createMutation, isLoading: createLoading } = useMutation(
    (data) =>
      api.post(
        setCurrentPath(JSON.parse(localStorage.getItem("user"))?.providerType),
        data
      ),
    {
      onSuccess: ({ data }) => {
        setAuthUser(data);

        window.dataLayer.push({
          event: "sign_up",
          type: "default",
        });
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const { mutate: updateMutation, isLoading: updateLoading } = useMutation(
    (data) =>
      JSON.parse(localStorage.getItem("user"))?.providerType ||
      JSON.parse(localStorage.getItem("sub"))
        ? api.put("/users/profile", data)
        : api.post("/auth/register", data),
    {
      onSuccess: async ({ data }) => {
        JSON.parse(localStorage.getItem("user"))?.providerType ||
        JSON.parse(localStorage.getItem("sub"))
          ? setAuthUser({
              accessToken: localStorage.getItem("accessToken"),
              refreshToken: localStorage.getItem("createProfileToken"),
            })
          : setAuthUser(data);

        await queryClient.invalidateQueries({
          queryKey: ["user"],
          refetchType: "all",
        });
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const handleNewUserCreate = (data) => {
    delete data.confirmPassword;
    if (
      JSON.parse(localStorage.getItem("user"))?.isExist === false &&
      localStorage.getItem("accessToken")
    ) {
      data = {
        profile: { ...data },
        accessToken: localStorage.getItem("accessToken"),
      };
    }
    if (
      JSON.parse(localStorage.getItem("user"))?.isExist === false &&
      localStorage.getItem("multipleUsageToken")
    ) {
      data = {
        ...data,
        multipleUsageToken: localStorage.getItem("multipleUsageToken"),
      };
    }

    createMutation(data);
  };

  const onSubmit = (data) => {
    JSON.parse(localStorage.getItem("user"))?.isExist === false
      ? handleNewUserCreate(data)
      : updateMutation(data);
  };

  useEffect(() => {
    searchParams.get("token") &&
      localStorage.setItem("accessToken", searchParams.get("token")) &&
      setAccessToken(searchParams.get("token"));
  }, [searchParams, setAccessToken]);

  return (
    <Box className={classes.wrapper}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <StepComponent
          register={register}
          errors={errors}
          clearErrors={clearErrors}
          resetField={resetField}
          trigger={trigger}
          control={control}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          onPrevStep={onPrevStep}
          isLoading={createLoading || updateLoading}
        />
        {step < steps.length - 1 ? (
          <AccentButton
            isBig={false}
            type="button"
            onClick={onNextStep}
            variant="contained"
            endIcon={<ChevronRightIcon />}
            fullWidth
            disabled={createLoading || updateLoading}
            className={classes.button}
          >
            Next
          </AccentButton>
        ) : (
          <AccentButton
            isBig={false}
            type="button"
            variant="contained"
            fullWidth
            disabled={createLoading || updateLoading}
            onClick={() => handleSubmit(onSubmit)()}
            className={classes.button}
          >
            Continue
          </AccentButton>
        )}
      </form>
    </Box>
  );
};

export default CreatePassword;
