import React from "react";
import classes from "./index.module.scss";
import Container from "../common/Container";
import SectionTitle from "../common/SectionTitle";
import HighlightedText from "../common/HighlightedText";
import Button from "../common/Button";

export default function Reminder(props) {
  const { title, link } = props;
  return (
    <section className={classes.wrapper}>
      <Container>
        <div className={classes.wrapper}>
          <SectionTitle className={classes.title}>
            <HighlightedText
              text={title}
              highlightedClassName={classes.title__highlighted}
            />
          </SectionTitle>
          {link ? (
            <Button link={link.link} label={link.text} icon={link.icon} />
          ) : null}
        </div>
      </Container>
    </section>
  );
}
