import { Box } from "@mui/material";
import Title from "./Title";
import Info from "./Info";
import Badges from "./Badges";
import Certificates from "./Certificates";
import BackLink from "../../../../components/Common/BackLink";
import useAppSize from "../../../../hooks/useAppSize";
import classes from "./index.module.scss";

export default function Dashboard({ rating, userId }) {
  const { width: windowWidth } = useAppSize();

  const isCurrentUser = userId === rating?.user.id;

  const title = {
    fullName: rating?.user.fullName,
    image: rating?.user.profilePhotoUrl,
  };
  const info = {
    completeCoursesCount: rating?.completeCoursesCount,
    position: rating?.userRating.position,
    score: rating?.userRating.score,
  };
  const badges = rating?.badges;
  const certificates = rating?.certificates;

  if (!rating) return null;
  return (
    <>
      {!isCurrentUser ? (
        <Box sx={{ marginBottom: "1rem", marginTop: "0.25rem" }}>
          <BackLink
            text="Back to Rating"
            path={"/rating"}
            isLogoVisible={false}
          />
        </Box>
      ) : null}
      <Box className={classes.body} sx={{ marginTop: "0.25rem" }}>
        <Title title={title} />
        <Info info={info} />
        <Certificates
          certificates={certificates}
          windowWidth={windowWidth}
          currentUserId={userId}
        />
        <Badges
          badges={badges}
          isCurrentUser={isCurrentUser}
          windowWidth={windowWidth}
          currentUserId={userId}
        />
      </Box>
    </>
  );
}
