import React from "react";
import Container from "../common/Container";
import classes from "./index.module.scss";
import SectionTitle from "../common/SectionTitle";
import Button from "../common/Button";
import BlockTitle from "../common/BlockTitle";
import RegularPrice from "../common/RegularPrice";
import CurrentPrice from "../common/CurrentPrice";
import HighlightedText from "../common/HighlightedText";
import ProgressiveImage from "../common/ProgressiveImage";

export default function Description(props) {
  const { title, link, subtitle, regular_price, current_price, card } = props;

  return (
    <section>
      <Container>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <SectionTitle className={classes.title}>{title}</SectionTitle>
            {subtitle ? (
              <BlockTitle className={classes.subtitle}>{subtitle}</BlockTitle>
            ) : null}
          </div>
          <ul className={classes.list}>
            {card.map((item) => (
              <li className={classes.card} key={item.id}>
                <ProgressiveImage
                  src={`${process.env.REACT_APP_CMS_DOMAIN}${item.image.url}`}
                  placeholderSrc={`${process.env.REACT_APP_CMS_DOMAIN}${item.image.formats.thumbnail.url}`}
                  alt={item.image.alternativeText}
                  width={item.image.width}
                  height={item.image.height}
                  containerClassName={classes.card__image}
                />
                <h4 className={classes.card__title}>{item.title}</h4>
              </li>
            ))}
          </ul>
          <div className={classes.footer}>
            {regular_price || current_price ? (
              <div className={classes.price}>
                {regular_price ? (
                  <RegularPrice>
                    <HighlightedText
                      text={regular_price.price}
                      highlightedClassName={classes.price__regular_highlighted}
                    />
                  </RegularPrice>
                ) : null}
                {current_price ? (
                  <CurrentPrice>
                    <HighlightedText
                      text={current_price.price}
                      highlightedClassName={classes.price__current_highlighted}
                    />
                  </CurrentPrice>
                ) : null}
              </div>
            ) : null}
            {link ? (
              <Button link={link.link} label={link.text} icon={link.icon} />
            ) : null}
          </div>
        </div>
      </Container>
    </section>
  );
}
