import Logo from "../common/Logo";
import Navbar from "./Navbar/Navbar";
import NavMenu from "../common/NavMenu";
import classes from "./index.module.scss";
import BurgerButton from "../common/BurgerButton";
import { useContext, useEffect, useState } from "react";
import DiscordButton from "./DiscordButton/DiscordButton";
import { AuthContext } from "../../../../context/AuthContext";
import AuthButton from "../../../Common/AuthButton/AuthButton";
import { AppBar, Box, Container, Toolbar } from "@mui/material";
import { ReactComponent as BurgerIcon } from "./images/burger.svg";
import { ReactComponent as UniversityLogo } from "../../../../assets/images/logo.svg";
import { ReactComponent as UniversityLightLogo } from "../../../../assets/images/logo-light.svg";
import { useGTM } from "../../../../hooks/useGTM";

export default function Header({ variant = "landing" }) {
  const isPlatformVariant = variant === "platform";
  const isPromoVariant = variant === "promo";

  const { trackEvent } = useGTM();
  const handleTrackDiscordButtonClick = (label) => {
    trackEvent({
      event: "header_discord_button_click",
      action: "click",
      label,
    });
  };
  const handleTrackLogoClick = () => {
    trackEvent({
      event: "header_logo_click",
      action: "click",
      label: "Header Logo",
    });
  };
  const handleTrackBurgerClick = () => {
    trackEvent({
      event: "header_burger_click",
      action: "click",
      label: "Header Burger",
    });
  };
  const handleTrackAuthButtonClick = () => {
    trackEvent({
      event: "header_sign_up_login_button_click",
      action: "click",
      label: "Sign up / Login",
    });
  };

  const { auth } = useContext(AuthContext);

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const header = document.getElementById("header");
    if (header) {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 24) {
          header.classList.add(classes.scrolled);
        } else {
          header.classList.remove(classes.scrolled);
        }
      });
    }
  }, []);

  const handleDrawerToggle = () => {
    setOpenMenu((prevState) => !prevState);
  };

  let bgColor = "var(--on-surface-a-100)";
  if (isPlatformVariant) {
    bgColor = "var(--on-surface-f)";
  }
  if (isPromoVariant) {
    bgColor = "transparent";
  }

  return (
    <>
      <AppBar
        className={`${classes.appBar} ${classes[variant]}`}
        component="header"
        sx={{
          position: isPlatformVariant && "sticky",
          backgroundColor: bgColor,
          boxShadow: "none",
        }}
        id="header"
      >
        <Container maxWidth="lg">
          <Toolbar
            className={classes.toolbar}
            sx={{
              minHeight: "unset !important",
              padding: "0px !important",
            }}
          >
            <Logo handleTrackLogoClick={handleTrackLogoClick}>
              {isPromoVariant ? <UniversityLightLogo /> : <UniversityLogo />}
            </Logo>

            <Box className={classes.box}>
              <Navbar className={classes.navbar} variant={variant} />
              <Box className={classes.discord}>
                <DiscordButton
                  variant={variant}
                  handleTrackDiscordButtonClick={handleTrackDiscordButtonClick}
                />
              </Box>
              <AuthButton
                handleTrackAuthButtonClick={handleTrackAuthButtonClick}
                className={classes.authButton}
                variant={variant}
              />
              <Box
                className={`${classes.burger} ${
                  isPlatformVariant ? classes.platformBurger : ""
                } ${isPromoVariant ? classes.promo : ""}`}
              >
                <BurgerButton
                  handleTrackBurgerClick={handleTrackBurgerClick}
                  handleDrawerToggle={handleDrawerToggle}
                >
                  <Box
                    className={`${classes.innerBurger} ${
                      auth ? "" : classes.auth
                    } ${isPromoVariant ? classes.promo : ""}`}
                  >
                    <BurgerIcon />
                  </Box>
                </BurgerButton>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <NavMenu
        open={openMenu}
        variant={variant}
        handleDrawerToggle={handleDrawerToggle}
      />
    </>
  );
}
