import { Box } from "@mui/material";
import { PlatformLayout } from "../../../../components/Layouts";
import classes from "./index.module.scss";
import Dashboard from "../Dashboard";
import { useStudentRating } from "../hooks/useStudentRating";
import Loader from "../../../../components/Common/Loader";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";

export default function UserRating() {
  const { user } = useContext(AuthContext);

  const id = user?.id;
  const { data: rating, isLoading: isRatingLoading } = useStudentRating({
    id,
  });

  const student = rating?.userRating?.usersRating;

  return (
    <PlatformLayout>
      {isRatingLoading ? <Loader /> : null}
      <Box className={classes.body}>
        <Dashboard rating={student} userId={id} />
      </Box>
    </PlatformLayout>
  );
}
