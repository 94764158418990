import { Link } from 'react-router-dom';
import classes from './DiscordButton.module.scss';
import { TransparentButton } from '../../../../MUI/buttons/button';
import { ReactComponent as DiscordIcon } from '../../../../../assets/images/discord-blue.svg';

export default function DiscordButton({
  className = "",
  variant,
  handleTrackDiscordButtonClick=()=>false,
}) {
  return (
    <TransparentButton
      isBig={false}
      component={Link}
      target="_blank"
      variant="contained"
      startIcon={<DiscordIcon />}
      to={process.env.REACT_APP_DISCORD_LINK}
      className={`${classes.root} ${className} ${classes[variant]}`}
      onClick={() => handleTrackDiscordButtonClick("Discord Community")}
    >
      Discord Community
    </TransparentButton>
  );
}
