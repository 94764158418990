import React, { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Common/Loader";
import { Container } from "@mui/material";
import { getYoutubePlaylistVideos } from "../../../services/youtube";
import Details from "./Details";
import { LandingLayout, PlatformLayout } from "../../../components/Layouts";

import classes from "./index.module.scss";
import { NotFound } from "../../root";

export default function CourseDetails({ isAuth, isAuthLoading }) {
  const { courseSlug } = useParams();
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching } = useQuery(
    isAuth
      ? ["course", "registered", courseSlug]
      : ["course", "unregistered", courseSlug],
    () =>
      api.get(
        `${
          isAuth
            ? process.env.REACT_APP_SINGLE_COURSE_PATH
            : process.env.REACT_APP_SINGLE_COURSE_UNREGISTERED_PATH
        }/${courseSlug}`
      ),
    {
      enabled: !isAuthLoading,
      staleTime: 0,
    }
  );

  const { data: feedbacks } = useQuery(
    ["course", "feedbacks", courseSlug],
    () => api.get(`/alumniFeedbacks/course/${data?.data.courses.id}/platform`),
    {
      enabled: !isAuthLoading && !!data?.data.courses,
      staleTime: Infinity,
    }
  );

  const alumniFeedbacks = feedbacks?.data.alumniFeedbacks;

  const { data: playlist } = useQuery(
    ["playlist", data?.data.courses.relatedMaterialsPlaylistUrl],
    () =>
      getYoutubePlaylistVideos(
        data?.data.courses.relatedMaterialsPlaylistUrl,
        process.env.REACT_APP_YOUTUBE_API_KEY
      ),
    {
      enabled:
        !isAuthLoading &&
        !isLoading &&
        !!data?.data.courses.relatedMaterialsPlaylistUrl,
    }
  );

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ["course", "registered", courseSlug],
    });
  }, [courseSlug, queryClient]);

  return (
    <>
      {isAuth ? (
        <PlatformLayout>
          {isLoading || isFetching ? <Loader /> : null}
          {data?.data.courses ? (
            <Details
              feedbacks={alumniFeedbacks}
              data={data.data.courses}
              playlist={playlist}
            />
          ) : (
            !isLoading && <NotFound />
          )}
        </PlatformLayout>
      ) : (
        <LandingLayout>
          {!isLoading && !data?.data.courses ? (
            <NotFound />
          ) : (
            <Container
              className={classes.wrapper}
              maxWidth={false}
              disableGutters
            >
              {isLoading ? <Loader /> : null}
              <Container className={classes.container} maxWidth="lg">
                {data?.data.courses ? (
                  <Details
                    feedbacks={alumniFeedbacks}
                    data={data.data.courses}
                    playlist={playlist}
                  />
                ) : null}
              </Container>
            </Container>
          )}
        </LandingLayout>
      )}
    </>
  );
}
