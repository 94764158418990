import { Button } from "../../MUI";
import { ReactComponent as Icon } from "../../../assets/images/chat.svg";
import classes from "./index.module.scss";

export default function DiscordButton() {
  return (
    <>
      <Button
        variant="outlined"
        className={`${classes.button} ${classes.button__desktop}`}
        startIcon={<Icon />}
        component={"a"}
        target="_blank"
        rel="noopener noreferrer"
        href="https://discord.gg/hjGcUdxJF4"
      >
        Ask question
      </Button>
      <Button
        variant="outlined"
        className={`${classes.button} ${classes.button__mobile}`}
        component={"a"}
        target="_blank"
        rel="noopener noreferrer"
        href="https://discord.gg/hjGcUdxJF4"
      >
        <Icon />
      </Button>
    </>
  );
}
