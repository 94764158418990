import React, { useContext } from "react";
import classes from "./index.module.scss";
import { Typography } from "@mui/material";
import {
  AccentButton,
  TransparentButton,
  WhiteButton,
} from "../../MUI/buttons/button";
import { calculatePercentageDifference } from "../../../helpers/calculatePercentageDifference";
import PlanDiscount from "../PlanDiscount";
import { formatCurrency } from "../../../helpers/formatCurrency";
import { SubscriptionsContext } from "../../../context/SubscriptionsContext";
import { AuthContext } from "../../../context/AuthContext";
import Modal from "../Modal";
import InfoBlock from "../InfoBlock";
import KeepSubscriptionModal from "./KeepSubscriptionModal";
import CancelDowngradeModal from "./CancelDowngradeModal";
import usePopup from "../../../hooks/usePopup";
import UnsubscribePopup from "../UnsubscribePopup";
import { useGTM } from "../../../hooks/useGTM";

export default function PlanCard({ card }) {
  const {
    currentSubscription,
    onRegisteredPaidClick,
    onUnregisteredFreeClick,
    onUnregisteredPaidClick,
    handleKeepPopupOpen,
    handleKeepPopupClose,
    keepPopupRef,
    cancelDowngradePopupOpen,
    cancelDowngradePopupClose,
    cancelDowngradePopupRef,
  } = useContext(SubscriptionsContext);
  const { trackEvent } = useGTM();
  const { auth } = useContext(AuthContext);
  const { open, handleClose, handleOpen } = usePopup();

  const backToFreeButtonLabel = "Back to Free Plan";
  const keepCurrentButtonLabel = "Keep Current Plan";

  const handleBackToFreeClick = () => {
    trackEvent({
      event: "unsubscribe_button_click",
      action: "click",
      label: backToFreeButtonLabel,
    });
    handleOpen();
  };

  const handleKeepCurrentClick = () => {
    trackEvent({
      event: "keep_current_button_click",
      action: "click",
      label: keepCurrentButtonLabel,
    });
    handleKeepPopupOpen();
  };

  const discount = card.originalPrice
    ? calculatePercentageDifference(card.originalPrice, card.price)
    : 0;

  const onPaidClick = (plan) =>
    auth ? onRegisteredPaidClick(plan) : onUnregisteredPaidClick(plan);

  return (
    <div
      className={`${classes.card} ${
        card.isHighlighted ? classes.highlighted : classes.regular
      }`}
    >
      <UnsubscribePopup open={open} handleClose={handleClose} />
      <Modal modalRef={keepPopupRef} className={classes.modal}>
        <KeepSubscriptionModal handlePopupClose={handleKeepPopupClose} />
      </Modal>
      <Modal modalRef={cancelDowngradePopupRef} className={classes.modal}>
        <CancelDowngradeModal handlePopupClose={cancelDowngradePopupClose} />
      </Modal>
      <div>
        <div className={classes.card__icon}>
          <img src={card.iconUrl} alt={card.name} width={24} />
        </div>
        <div className={classes.card__info}>
          <div className={classes.card__heading}>
            <Typography component={"h3"} className={classes.card__name}>
              {card.name}
            </Typography>
            {discount ? <PlanDiscount>Save {discount}%</PlanDiscount> : null}
          </div>
          {!card.isFree ? (
            <Typography className={classes.card__price}>
              {card.originalPrice ? (
                <>
                  <span className={classes.card__originalPrice}>
                    {formatCurrency(card.originalPrice)}
                  </span>{" "}
                </>
              ) : null}
              {formatCurrency(card.price)}
              <span className={classes.card__period}> / {card.period}</span>
            </Typography>
          ) : (
            <>
              <Typography className={classes.card__price}>Free</Typography>
              <span className={classes.card__price_free}>Sign up only</span>
            </>
          )}
        </div>

        <p className={classes.card__access}>Access to:</p>
        <ul className={classes.card__features}>
          {card.features.map((feature) => (
            <li className={classes.feature} key={feature.id}>
              <div className={classes.feature__icon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    d="M8.33329 2.5L3.74996 7.08333L1.66663 5"
                    stroke="white"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span className={classes.feature__text}>{feature.name}</span>
            </li>
          ))}
        </ul>
      </div>
      {currentSubscription?.plan.id !== card.id ? (
        card.isHighlighted ? (
          !currentSubscription?.downgradePlan ? (
            <WhiteButton
              onClick={() =>
                !card.isFree ? onPaidClick(card) : onUnregisteredFreeClick()
              }
              isBig={false}
              variant="contained"
              fullWidth
            >
              {!card.isFree ? `Get ${card.name} Plan` : "Get Started"}
            </WhiteButton>
          ) : (
            <div className={classes.card__subscription_canceled}>
              <InfoBlock isHighlighted={card.isHighlighted}>
                Downgrade changes will be applied starting next billing period.
                Until then, you can continue using current plan features.
              </InfoBlock>
              <TransparentButton
                onClick={cancelDowngradePopupOpen}
                isBig={false}
                variant="outlined"
                fullWidth
              >
                Cancel downgrade request
              </TransparentButton>
            </div>
          )
        ) : card.isFree && auth ? (
          !!currentSubscription?.cancelledAt ? (
            <div className={classes.card__subscription_canceled}>
              <InfoBlock>
                Your plan will be canceled starting next billing period. Until
                then, you can continue using current plan features.
              </InfoBlock>
              <WhiteButton
                onClick={handleKeepCurrentClick}
                isBig={false}
                variant="outlined"
                fullWidth
              >
                {keepCurrentButtonLabel}
              </WhiteButton>
            </div>
          ) : (
            <WhiteButton
              onClick={handleBackToFreeClick}
              isBig={false}
              variant="outlined"
              fullWidth
            >
              {backToFreeButtonLabel}
            </WhiteButton>
          )
        ) : (
          <div className={classes.card__subscription_canceled}>
            {!!currentSubscription?.cancelledAt &&
            Number(currentSubscription.plan.price) <= Number(card.price) ? (
              <InfoBlock isHighlighted={card.isHighlighted}>
                {`In order to upgrade to ${card.name} Plan please cancel downgrade to
                Free Plan first`}
              </InfoBlock>
            ) : null}
            <AccentButton
              onClick={() =>
                !card.isFree ? onPaidClick(card) : onUnregisteredFreeClick()
              }
              isBig={false}
              variant="contained"
              fullWidth
              disabled={!!currentSubscription?.cancelledAt}
            >
              {!card.isFree ? `Get ${card.name} Plan` : "Get Started"}
            </AccentButton>
          </div>
        )
      ) : (
        <Typography className={classes.card__current}>Current plan</Typography>
      )}
    </div>
  );
}
