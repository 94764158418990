import { useQuery } from "@tanstack/react-query";
import { Box, Container, Typography } from "@mui/material";
import classes from "./FeaturedCourses.module.scss";
import { api } from "../../../../instances/axiosInstances";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import Carousel from "./Carousel";
import { AccentButton } from "../../../../components/MUI/buttons/button";
import { Link } from "react-router-dom";
import { useGTM } from "../../../../hooks/useGTM";

export default function FeaturedCourses({ title }) {
  const { auth } = useContext(AuthContext);
   const { trackEvent } = useGTM();
   const handleButtonClick = (text) => {
     trackEvent({
       event: "cta_button_click",
       action: "click",
       label: text,
     });
   };

  const COURSES_API_URL = auth
    ? process.env.REACT_APP_COURSES_REGISTERED_PATH
    : process.env.REACT_APP_COURSES_UNREGISTERED_PATH;

  const coursesApiResponse = useQuery(
    ["featured-courses", auth ? "registered" : "unregistered"],
    () => api.get(COURSES_API_URL),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    }
  );

  const items = coursesApiResponse.isSuccess
    ? coursesApiResponse.data?.data?.courses
    : [];

  if (!items || !items.length) return null;

  return (
    <Box className={classes.section} component="section">
      <Container maxWidth="lg">
        <Typography className={classes.title} component="h2">
          {title}
        </Typography>
        <div className={classes.carousel}>
          <Carousel data={items} />
          <AccentButton
            isBig={false}
            variant="contained"
            component={Link}
            to={"/courses"}
            className={classes.button}
            fullWidth
            onClick={() => handleButtonClick("ALL COURSES")}
          >
            ALL COURSES
          </AccentButton>
        </div>
      </Container>
    </Box>
  );
}
