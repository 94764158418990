import { useLocation, useNavigate } from "react-router-dom";

export default function useButtonClick() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onStartForFreeClick = () => {
    localStorage.setItem("redirectPath", pathname);
    navigate("/registration");
  };

  const onExternalLinkClick = (isFree) => {
    localStorage.setItem("externalLink", JSON.stringify(isFree));
  };
  const removeExternalLink = () => {
    localStorage.removeItem("externalLink");
  };

  return {
    onStartForFreeClick,
    onExternalLinkClick,
    removeExternalLink,
  };
}
