import { useContext } from "react";
import classes from "./AuthButton.module.scss";
import useButtonClick from "../../../hooks/useButtonClick";
import { AuthContext } from "../../../context/AuthContext";
import { AccentButton, WhiteButton } from "../../MUI/buttons/button";

export default function AuthButton({
  variant = "header",
  className = "",
  handleTrackAuthButtonClick = () => false,
}) {
  const { auth } = useContext(AuthContext);

  const { onStartForFreeClick } = useButtonClick();

  if (auth) return null;

  return (
    <div className={`${classes.root} ${className}`}>
      {["header", "landing", "platform"].includes(variant) ? (
        <AccentButton
          isBig={false}
          variant="outlined"
          rel="noreferrer"
          onClick={() => {
            onStartForFreeClick();
            handleTrackAuthButtonClick();
          }}
        >
          <span className={classes.mobText}>Sign In</span>
          <span className={classes.deskText}>Sign Up / Login</span>
        </AccentButton>
      ) : (
        <WhiteButton
          isBig={false}
          variant={variant === "promo" ? "promo" : "outlined"}
          rel="noreferrer"
          onClick={() => {
            onStartForFreeClick();
            handleTrackAuthButtonClick("Sign Up / Login");
          }}
        >
          Sign Up / Login
        </WhiteButton>
      )}
    </div>
  );
}
