import { useTheme } from "@emotion/react";
import { Box, Container, Stack } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../../../assets/images/logo-light.svg";
import { ReactComponent as Mail } from "../../../../assets/images/mail.svg";
import { ReactComponent as FacebookIcon } from "../../../../assets/images/facebook.svg";
import { ReactComponent as YoutubeIcon } from "../../../../assets/images/youtube-white.svg";
import { ReactComponent as DiscordIcon } from "../../../../assets/images/discord-white.svg";
import { Text } from "../../../MUI";
import classes from "./index.module.scss";
import { useQuery } from "@tanstack/react-query";
import { apiCmsData } from "../../../../instances/axiosInstances";
import { useGTM } from "../../../../hooks/useGTM";

export default function Footer({ variant }) {
  const itemsRes = useQuery(["footer"], () =>
    apiCmsData.get(
      `/footer${process.env.REACT_APP_WP_HOME_QUERY_PARAMS}&order=asc`
    )
  );

  const { trackEvent } = useGTM();
  const handleTrackSocialButtonClick = (label) => {
    trackEvent({
      event: "footer_social_button_click",
      action: "click",
      label,
    });
  };
  const handleTrackLogoClick = () => {
    trackEvent({
      event: "footer_logo_click",
      action: "click",
      label: "Footer Logo",
    });
  };
  const handleTrackNavLinkClick = (label) => {
    trackEvent({
      event: "footer_navigation_link_click",
      action: "click",
      label,
    });
  };

  const handleTrackEmailClick = (email) => {
    trackEvent({
      event: "footer_email_click",
      action: "click",
      label: email,
    });
  };

  const items = itemsRes.isSuccess ? itemsRes?.data?.data : [];

  const theme = useTheme();
  const year = new Date().getFullYear();

  if (!items.length) return null;

  return (
    <footer className={`${classes.footer} ${classes[variant]}`}>
      <Container maxWidth="lg" className={classes.body}>
        <Box>
          <NavLink to="/" onClick={handleTrackLogoClick}>
            <Logo />
          </NavLink>

          <Text
            variant="text-12"
            sx={() => ({
              color: theme.palette["white-70"],
              mt: 1.5,
            })}
          >
            {year} Engenious Inc. (c) All rights reserved
          </Text>
        </Box>
        <Box className={classes.menu}>
          <Stack className={classes.menu__item}>
            <Link
              to={items[0]?.custom_fields?.button_link[0]}
              className={classes.menu__link}
              onClick={() =>
                handleTrackNavLinkClick(items[0]?.custom_fields?.button[0])
              }
            >
              <Text
                variant="text-14"
                sx={() => ({
                  color: theme.palette["white"],
                })}
              >
                {items[0]?.custom_fields?.button}
              </Text>
            </Link>
            <Link
              to={items[1]?.custom_fields?.button_link[0]}
              className={classes.menu__link}
              onClick={() =>
                handleTrackNavLinkClick(items[1]?.custom_fields?.button[0])
              }
            >
              <Text
                variant="text-14"
                sx={() => ({
                  color: theme.palette["white"],
                })}
              >
                {items[1]?.custom_fields?.button}
              </Text>
            </Link>
            <a
              href={items[2]?.custom_fields?.button_link[0]}
              className={classes.menu__link}
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                handleTrackNavLinkClick(items[2]?.custom_fields?.button[0])
              }
            >
              <Text
                variant="text-14"
                sx={() => ({
                  color: theme.palette["white"],
                })}
              >
                {items[2]?.custom_fields?.button}
              </Text>
            </a>
            <Link
              to={"/subscriptions"}
              className={classes.menu__link}
              onClick={() => handleTrackNavLinkClick("Pricing")}
            >
              <Text
                variant="text-14"
                sx={() => ({
                  color: theme.palette["white"],
                })}
              >
                Pricing
              </Text>
            </Link>
          </Stack>
          <Stack className={classes.menu__item}>
            <a
              className={classes.menu__link}
              href={`mailto:${process.env.REACT_APP_ENGENIOUS_EMAIL}`}
              onClick={() =>
                handleTrackEmailClick(process.env.REACT_APP_ENGENIOUS_EMAIL)
              }
            >
              <Text
                variant="text-14"
                sx={() => ({
                  color: theme.palette["white"],
                })}
              >
                {items[3]?.custom_fields?.button}
              </Text>
            </a>
            <Link
              to={items[4]?.custom_fields?.button_link[0]}
              className={classes.menu__link}
              onClick={() =>
                handleTrackNavLinkClick(items[4]?.custom_fields?.button[0])
              }
            >
              <Text
                variant="text-14"
                sx={() => ({
                  color: theme.palette["white"],
                })}
              >
                {items[4]?.custom_fields?.button}
              </Text>
            </Link>
            <Link
              to={items[5]?.custom_fields?.button_link[0]}
              className={classes.menu__link}
              onClick={() =>
                handleTrackNavLinkClick(items[5]?.custom_fields?.button[0])
              }
            >
              <Text
                variant="text-14"
                sx={() => ({
                  color: theme.palette["white"],
                })}
              >
                {items[5]?.custom_fields?.button}
              </Text>
            </Link>
          </Stack>
          <Stack className={classes.menu__contacts}>
            <Stack direction="row" sx={{ gap: 1.5 }}>
              <a
                href={process.env.REACT_APP_YOUTUBE_LINK}
                target="_blank"
                rel="noreferrer"
                onClick={() => handleTrackSocialButtonClick("youtube")}
              >
                <YoutubeIcon />
              </a>
              <a
                href={process.env.REACT_APP_FACEBOOK_LINK}
                target="_blank"
                rel="noreferrer"
                onClick={() => handleTrackSocialButtonClick("facebook")}
              >
                <FacebookIcon />
              </a>
              <a
                href={process.env.REACT_APP_DISCORD_LINK}
                target="_blank"
                rel="noreferrer"
                onClick={() => handleTrackSocialButtonClick("discord")}
              >
                <DiscordIcon />
              </a>
            </Stack>

            <Stack direction="row" sx={{ gap: 1 }}>
              <Box>
                <Mail />
              </Box>
              <a
                className={classes.menu__link}
                href={`mailto:${process.env.REACT_APP_ENGENIOUS_EMAIL}`}
                onClick={() =>
                  handleTrackEmailClick(process.env.REACT_APP_ENGENIOUS_EMAIL)
                }
              >
                <Text
                  variant="text-14"
                  sx={() => ({
                    color: theme.palette["white"],
                  })}
                >
                  university@engenious.io
                </Text>
              </a>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </footer>
  );
}
