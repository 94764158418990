import classes from "./index.module.scss";
import { Input } from "../../../MUI/inputs";

export default function Textarea({
  Controller,
  control,
  errors,
  name,
  rows = 8,
  label = "Review",
  placeholder = "Add review about course",
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          multiline
          className={classes.textarea}
          rows={rows}
          label={label}
          placeholder={placeholder}
          type="text"
          margin="normal"
          variant="outlined"
          fullWidth
          autoComplete="new-password"
          InputLabelProps={{
            shrink: true,
            disableAnimation: true,
          }}
          error={!!errors[name]}
          helperText={errors[name] && errors[name].message}
          inputProps={{ "data-lpignore": "true" }}
        />
      )}
    />
  );
}
