import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

export const QuestionnaireRoute = ({
  isAuthenticated,
  children,
  isAuthLoading,
  user,
}) => {
  if (!isAuthenticated && !isAuthLoading) {
    return <Navigate to="/login" replace />;
  }

  if (isAuthenticated && user.isQuestionnaireComplete) {
    return <Navigate to="/profile" replace />;
  }

  return children;
};

export const ProtectedRoute = ({
  isAuthenticated,
  children,
  isAuthLoading,
  redirectPath = "/",
}) => {
  const {pathname} = useLocation();
  useEffect(() => {
    localStorage.setItem("redirectPath", pathname)
  }, [pathname])
  
  if (!isAuthenticated && !isAuthLoading) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
