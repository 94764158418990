import React, { useState } from "react";
import classes from "./index.module.scss";
import Container from "../common/Container";
import ProgressiveImage from "../common/ProgressiveImage";
import ReactPlayer from "react-player";

export default function Hero(props) {
  const { title, poster, videoLink } = props;
  const [ready, setReady] = useState(false);

  return (
    <section>
      <Container>
        <div className={classes.wrapper}>
          <h1 className={classes.title}>{title}</h1>
          <div className={classes.media}>
            <div className={classes.player}>
              {!ready ? (
                <ProgressiveImage
                  src={`${process.env.REACT_APP_CMS_DOMAIN}${poster.url}`}
                  placeholderSrc={`${process.env.REACT_APP_CMS_DOMAIN}${poster.formats.thumbnail.url}`}
                  alt={poster.alternativeText}
                  width={poster.width}
                  height={poster.height}
                  containerClassName={classes.poster}
                />
              ) : null}
              <ReactPlayer
                url={videoLink}
                loop
                muted
                controls
                playing
                width={"100%"}
                height={"100%"}
                onPlay={() => setReady(true)}
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
