import { BurgerButton as Button } from "../../../../MUI/buttons/button";
import classes from "./index.module.scss";

export default function BurgerButton({
  handleDrawerToggle,
  children,
  handleTrackBurgerClick = () => false,
}) {
  return (
    <Button
      variant={"contained"}
      aria-label="open drawer"
      onClick={() => {
        handleDrawerToggle();
        handleTrackBurgerClick();
      }}
      className={classes.burger}
    >
      {children}
    </Button>
  );
}
