import { Box } from "@mui/material";
import { AccentButton, WhiteButton } from "../../MUI/buttons/button";
import FormWrapper from "./FormWrapper";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import errorToast from "../../toasts/errorToast";
import { api } from "../../../instances/axiosInstances";
import Rating from "./Rating";
import Textarea from "./Textarea";
import Instructor from "./Instructor";
import { useNavigate, useParams } from "react-router-dom";

import classes from "./index.module.scss";

const validationSchema = yup.object().shape({
  alumniFeedbacks: yup.array().of(
    yup.object().shape({
      instructor: yup.number().required(),
      text: yup.string(),
      rating: yup
        .number()
        .min(0.1, "Rating is required")
        .required("Rating is required"),
    })
  ),
});

export default function InstructorsForm({
  instructors,
  courseId,
  handleClose,
  userId,
}) {
  const defaultAlumniFeedbacks = instructors.reduce((acc, instructor) => {
    const item = { instructor: instructor.id, text: "", rating: 0 };
    return [...acc, item];
  }, []);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      alumniFeedbacks: defaultAlumniFeedbacks,
    },
  });

  const queryClient = useQueryClient();
const {slug:courseSlug} = useParams()
  const navigate = useNavigate();

  const {
    mutate: certificateMutation,
    isLoading: isCertificateMutationLoading,
  } = useMutation((data) => api.post(`/certificates`, data), {
    onSuccess: async ({ data }) => {
      await queryClient.invalidateQueries({
        queryKey: ["course", "registered", courseSlug],
        refetchType: "all",
        exact: true,
      });
       await queryClient.invalidateQueries({
         queryKey: ["user"],
         refetchType: "all",
       });
       await queryClient.invalidateQueries({
         queryKey: ["usersRating", "registered", "general"],
         refetchType: "all",
         exact: true,
       });
       await queryClient.invalidateQueries({
         queryKey: ["userRating", userId],
         refetchType: "all",
         exact: true,
       });
      handleClose();
      navigate(`/rating/${userId}/certificate/${data.certificates.id}`);
    },
    onError: (error) => {
      errorToast(error.response.data.message);
    },
  });

  const {
    mutate: instructorsReviewMutation,
    isLoading: isInstructorsReviewMutationLoading,
  } = useMutation(
    (data) => api.post(`/instructorsAlumniFeedbacks/platform`, data),
    {
      onSuccess: () => {
        const data = {
          course: courseId,
        };

        certificateMutation(data);
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const createErrorObject = (index, newKey, oldKey) => {
    const error = errors.alumniFeedbacks.find(
      (_, errorIndex) => errorIndex === index
    );

    if (error[oldKey] && oldKey !== newKey) {
      Object.defineProperty(
        error,
        newKey,
        Object.getOwnPropertyDescriptor(error, oldKey)
      );
    } else if (error[newKey]) {
      delete error[newKey];
    }
    return error;
  };

  const onSubmit = async (data) => {
    instructorsReviewMutation(data);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper>
        {instructors.map((instructor, index, self) => {
          return (
            <FormWrapper key={instructor.id}>
              <Instructor
                fullName={instructor.fullName}
                image={instructor.photoUrl}
                position={instructor.position}
              />
              <Rating
                Controller={Controller}
                control={control}
                errors={
                  errors.alumniFeedbacks
                    ? createErrorObject(
                        index,
                        `alumniFeedbacks[${index}].rating`,
                        "rating"
                      )
                    : {}
                }
                name={`alumniFeedbacks[${index}].rating`}
              />

              <Textarea
                Controller={Controller}
                control={control}
                errors={
                  errors.alumniFeedbacks
                    ? createErrorObject(
                        index,
                        `alumniFeedbacks[${index}].text`,
                        "text"
                      )
                    : {}
                }
                name={`alumniFeedbacks[${index}].text`}
              />
              {self.length - 1 !== index ? (
                <div className={classes.line} />
              ) : null}
            </FormWrapper>
          );
        })}
        <Box className={classes.actions}>
          <WhiteButton isBig={false} variant="contained" onClick={handleClose}>
            Cancel
          </WhiteButton>
          <AccentButton
            isBig={false}
            variant="contained"
            type="submit"
            disabled={
              isInstructorsReviewMutationLoading || isCertificateMutationLoading
            }
          >
            Send
          </AccentButton>
        </Box>
      </FormWrapper>
    </form>
  );
}
