import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { Accordion, Summary, Details } from "../Accordion/ModuleAccordion";
import Header from "./Header";
import Content from "./Content";
import useAccordion from "../../../../hooks/useAccordion";
import classes from "./index.module.scss";

export default function Modules({ modules, courseId, isPaid }) {
  const { expanded, handleChange } = useAccordion();
  const { courseSlug } = useParams();

  if (!modules || !modules.length) {
    return null
  }
  return (
    <Box className={classes.modules}>
      {modules.map((module, index) => {
        let lecturesDuration = module.lectures.reduce(
          (acc, { duration }) => acc + duration,
          0
        );
        return (
          <Accordion
            expanded={expanded}
            handleChange={handleChange}
            index={index}
            key={module.id}
          >
            <Summary index={index}>
              <Header
                title={module.title}
                lectures={module.lectures.length}
                duration={lecturesDuration}
              />
            </Summary>
            <Details>
              <Content list={module.lectures} isPaid={isPaid} resources={module.additionalResources} courseSlug={courseSlug} />
            </Details>
          </Accordion>
        );
      })}
    </Box>
  );
}
