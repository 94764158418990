import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import { Box } from "@mui/system";
import errorToast from "../../../components/toasts/errorToast";
import successToast from "../../../components/toasts/successToast";
import { WhiteButton } from "../../../components/MUI/buttons/button";
import { Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import BackLink from "../../../components/Common/BackLink";
import classes from "./index.module.scss";

const ResendPassword = () => {
  const { user } = useContext(AuthContext);

  const { mutate, isLoading } = useMutation(
    (data) => api.post("/auth/confirmRegistration", data),
    {
      onSuccess: ({ data }) => {
        successToast(data.message);
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const onClick = () => {
    const newUser = user || JSON.parse(localStorage.getItem("user"));
    newUser && mutate(newUser);
  };

  if (!user?.email && !JSON.parse(localStorage.getItem("user"))?.email) {
    return <Navigate to={"/registration"} replace />
  }

  return (
    <Box className={classes.wrapper}>
      <BackLink path={"/registration"} />
      <Typography component={"h2"} variant={"h2"} className={classes.title}>
        Verify email
      </Typography>
      <Typography className={classes.text}>
        But before we can enrol you to Engenious University we need to verify
        that we got your email correctly. Please press on the “Verify email”
        button in the letter that we’ve just sent you to{" "}
        <span>
          {user?.email ||
            JSON.parse(localStorage.getItem("user"))?.email ||
            "Your email"}
        </span>
      </Typography>
      <WhiteButton
        isBig={false}
        variant="outlined"
        disabled={isLoading}
        fullWidth
        onClick={onClick}
        className={classes.button}
      >
        {isLoading ? "Loading..." : "Resend confirmation link"}
      </WhiteButton>
    </Box>
  );
};

export default ResendPassword;
