import { useCallback, useContext } from "react";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import { AuthContext } from "../context/AuthContext";

export const useGTM = () => {
  const { user: currentUser } = useContext(AuthContext);
  const {currentSubscription}=useContext(SubscriptionsContext)

  const initializeGTM = useCallback(() => {
    if (!window.dataLayer) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
    }
  }, []);

  const trackEvent = useCallback(
    (eventData = {}) => {
      initializeGTM();

      if (!window.dataLayer) {
        console.warn("GTM dataLayer is not available");
        return;
      }

      const defaultEvent = {
        event: "customEvent",
        user: currentUser
          ? {
              id: currentUser.id,
              subscription: currentSubscription
                ? {
                    id: currentSubscription.plan.id,
                    isFree: currentSubscription.plan.isFree,
                    name: currentSubscription.plan.name,
                    period: currentSubscription.plan.period,
                    price: currentSubscription.plan.price,
                  }
                : undefined,
            }
          : undefined,
        timestamp: new Date().toISOString(),
        ...eventData,
      };

      try {
        window.dataLayer.push(defaultEvent);
      } catch (error) {
        console.error("Error tracking GTM event:", error);
      }
    },
    [currentSubscription, currentUser, initializeGTM]
  );

  return { trackEvent };
};
