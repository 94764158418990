import React from "react";
import classes from "./index.module.scss";
import { AccentButton, WhiteButton } from "../../MUI/buttons/button";
import calendar from "./icons/Calendar.svg";
import chat from "./icons/Chat.svg";
import discount from "./icons/Discount.svg";
import { useGTM } from "../../../hooks/useGTM";

export default function Popup1({
  handleClose,
  cancelSubscriptionApi,
  unsubscribeApi,
}) {
  const { trackEvent } = useGTM();
  const keepButtonLabel = "Keep My Subscription";
  const unsubscribeButtonLabel = "Unsubscribe";

  const handleKeepClick = () => {
    trackEvent({
      event: "keep_button_click",
      action: "click",
      label: keepButtonLabel,
    });
    handleClose();
  };

  const handleUnsubscribeClick = () => {
    trackEvent({
      event: "unsubscribe_button_click",
      action: "click",
      label: unsubscribeButtonLabel,
    });
    unsubscribeApi.mutate();
  };

  return (
    <div className={classes.content}>
      <div className={classes.text}>
        <h3 className={classes.title}>Are you sure you want to leave?</h3>
        <p className={classes.description}>Here’s What You’ll Miss Out On:</p>
      </div>
      <ul className={classes.list}>
        <li className={classes.list__item}>
          <img src={calendar} alt="Calendar" width={44} height={44} />
          <div className={classes.list__item_content}>
            <b>Weekly Content Updates</b>
            <span>Stay up-to-date with the latest SDK updates.</span>
          </div>
        </li>
        <li className={classes.list__item}>
          <img src={chat} alt="Chat" width={44} height={44} />
          <div className={classes.list__item_content}>
            <b>Full-Time Support</b>
            <span>
              Direct access to top field experts in Discord community.
            </span>
          </div>
        </li>
        <li className={classes.list__item}>
          <img src={discount} alt="Discount" width={44} height={44} />
          <div className={classes.list__item_content}>
            <b>Proven Record</b>
            <span>Get certification and prove your achievements.</span>
          </div>
        </li>
      </ul>
      <div className={classes.actions}>
        <AccentButton
          onClick={handleKeepClick}
          isBig={false}
          variant={"contained"}
          fullWidth
          disabled={unsubscribeApi.isLoading || cancelSubscriptionApi.isLoading}
        >
          {keepButtonLabel}
        </AccentButton>
        <WhiteButton
          isBig={false}
          variant={"contained"}
          fullWidth
          onClick={handleUnsubscribeClick}
          disabled={unsubscribeApi.isLoading || cancelSubscriptionApi.isLoading}
        >
          {unsubscribeButtonLabel}
        </WhiteButton>
      </div>
    </div>
  );
}
