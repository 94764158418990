import React from "react";
import { AccentButton, WhiteButton } from "../../MUI/buttons/button";
import promo from "./icons/promo.png";
import { useCountdown } from "../../../hooks/useCountDown";
import classes from "./index.module.scss";
import { useGTM } from "../../../hooks/useGTM";

export default function Popup2({
  cancelSubscriptionApi,
  setPopupKey,
  promoCodeData,
  redeemApi,
}) {
  const { trackEvent } = useGTM();
  const targetTimestamp = promoCodeData.expiresAt;
  const { timeLeft } = useCountdown(targetTimestamp);
  const redeemButtonLabel = "Redeem Promo Code";
  const unsubscribeButtonLabel = "Unsubscribe anyway";

  const isLessThenTen = (number) => {
    if (!number) return "00";
    return number < 10 ? `0${number}` : number;
  };

  const hours = isLessThenTen(timeLeft.hours);
  const minutes = isLessThenTen(timeLeft.minutes);
  const seconds = isLessThenTen(timeLeft.seconds);

  if (timeLeft.expired) {
    setPopupKey("popup1");
  }

  const handleRedeemPromoClick = () => {
    trackEvent({
      event: "redeem_promo_button_click",
      action: "click",
      label: redeemButtonLabel,
    });
    redeemApi.mutate({ codeId: promoCodeData.codeId });
  };

  const handleUnsubscribeClick = () => {
    trackEvent({
      event: "unsubscribe_button_click",
      action: "click",
      label: unsubscribeButtonLabel,
    });
    cancelSubscriptionApi.mutate();
  };

  return (
    <div className={classes.content}>
      <div className={classes.text}>
        <h3 className={classes.title}>We don’t want to lose you!</h3>
        <p className={classes.description}>
          Here’s a <span className={classes.underline}>20% off</span> promo code
          for the next month. Code valid for{" "}
          <span className={classes.timer}>
            {hours}:{minutes}:{seconds}
          </span>
        </p>
      </div>
      <div className={classes.image}>
        <img src={promo} alt="Promo code" width={408} height={203} />
      </div>
      <div className={classes.actions}>
        <AccentButton
          onClick={handleRedeemPromoClick}
          disabled={cancelSubscriptionApi.isLoading || redeemApi.isLoading}
          isBig={false}
          variant={"contained"}
          fullWidth
        >
          {redeemButtonLabel}
        </AccentButton>
        <WhiteButton
          onClick={handleUnsubscribeClick}
          disabled={cancelSubscriptionApi.isLoading || redeemApi.isLoading}
          isBig={false}
          variant={"contained"}
          fullWidth
        >
          {unsubscribeButtonLabel}
        </WhiteButton>
      </div>
    </div>
  );
}
