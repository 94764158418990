import {
  Link,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { Box, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useContext, useEffect, useState } from "react";
import Player from "./Player";
import Poster from "./Poster";
import ShowMore from "../../../../components/Common/ShowMore";
import GetFullAccess from "./GetFullAccess";
import PreparationTasksImage from "./PreparationTasksImage";
import classes from "./index.module.scss";
import {
  AccentButton,
  WhiteButton,
} from "../../../../components/MUI/buttons/button";
import Workplace from "./Workplace";
import ClosedLectionImage from "./ClosedLectionImage";
import {
  getCurrentLectureIsFree,
  getCurrentLectureTitle,
} from "../helpers/lections";
import BackLink from "../../../../components/Common/BackLink";
import HTMLComponent from "../../../../components/Common/HTMLComponent";
import { ProgressPopupContext } from "../../../../context/ProgressPopupContext";

export default function Lecture() {
  const {
    isPaid,
    lecture,
    pages,
    lectureId,
    courseId,
    isCourseLoading,
    promoImageUrl,
    isFree,
  } = useOutletContext();
  const { handleOpen } = useContext(ProgressPopupContext);

  const { pathname } = useLocation();
  const { courseSlug } = useParams();
  const [nextPage, setNextPage] = useState(true);
  const [prevPage, setPrevPage] = useState(true);
  const [isCurrentLectureComplete, setCurrentLectureComplete] =
    useState(undefined);
  const [currentLectureDuration, setCurrentLectureDuration] = useState(0);

  const getPrevPageId = (pages, currentPageId) => {
    const currentIndex = pages.findIndex((item) => {
      return item.id === +currentPageId;
    });

    const prevPageIndex = currentIndex - 1;
    return pages.length > 1 && currentIndex !== -1
      ? setPrevPage(pages[prevPageIndex])
      : setNextPage(undefined);
  };
  const getNextPageId = (pages, currentPageId) => {
    const currentIndex = pages.findIndex((item) => {
      return item.id === +currentPageId;
    });

    const nextPageIndex = currentIndex + 1;
    return pages.length > 1 && currentIndex !== -1
      ? setNextPage(pages[nextPageIndex])
      : setNextPage(undefined);
  };

  const getCurrentLectureCompleteStatus = (pages, currentPageId) => {
    const currentLecture = pages.find((item) => {
      return item.id === +currentPageId;
    });
    setCurrentLectureDuration(currentLecture?.duration);
    setCurrentLectureComplete(currentLecture?.isComplete);
  };

  useEffect(() => {
    getPrevPageId(pages, lectureId);
    getNextPageId(pages, lectureId);
    getCurrentLectureCompleteStatus(pages, lectureId);
  }, [lectureId, pages]);

  useEffect(() => {
    localStorage.removeItem("redirectPath");
  }, []);

  return (
    <>
      <Box className={`${classes.lecture} ${classes.lecture__mobile}`}>
        <Box className={classes.header}>
          <Box className={classes.back}>
            <BackLink
              path={`/courses/${courseSlug}`}
              text="Back to Course Page"
              isLogoVisible={false}
            />
          </Box>
        </Box>
        {!isPaid ? <GetFullAccess isFree={isFree} /> : null}

        <Typography className={classes.title}>
          {pathname.includes("preparation-tasks")
            ? "Preparation tasks"
            : lecture?.data.coursesLectures?.title ||
              getCurrentLectureTitle(pages, lectureId)}
        </Typography>
        <Box className={classes.player}>
          {pathname.includes("preparation-tasks") ? (
            promoImageUrl ? (
              <PreparationTasksImage src={promoImageUrl} />
            ) : null
          ) : !lecture && !getCurrentLectureIsFree(pages, lectureId) ? (
            <ClosedLectionImage src={promoImageUrl} />
          ) : lecture?.data.coursesLectures?.videoLink ? (
            <Player
              nextPage={nextPage}
              videoLink={lecture?.data.coursesLectures?.videoLink}
              videoTitle={lecture?.data.coursesLectures?.title}
              isPaid={isPaid}
              poster={promoImageUrl}
              lectureId={lectureId}
              isComplete={isCurrentLectureComplete}
              duration={currentLectureDuration}
              isCourseLoading={isCourseLoading}
              courseSlug={courseSlug}
            />
          ) : (
            <Poster poster={promoImageUrl} />
          )}
        </Box>
        {!pathname.includes("preparation-tasks") && (nextPage || prevPage) ? (
          <Box className={classes.actions}>
            {prevPage ? (
              <WhiteButton
                isBig={false}
                variant="outlined"
                component={Link}
                to={`/courses/${courseSlug}/lecture/${prevPage?.id}`}
                onClick={(e) => {

                  handleOpen();
                }}
                startIcon={<ChevronLeftIcon />}
                className={classes.actions__prev}
              >
                Previous
              </WhiteButton>
            ) : null}
            {nextPage ? (
              <AccentButton
                isBig={false}
                variant="contained"
                component={Link}
                to={`/courses/${courseSlug}/lecture/${prevPage?.id}`}
                onClick={(e) => {

                  handleOpen();
                }}
                endIcon={<ChevronRightIcon />}
                className={classes.actions__next}
              >
                Next
              </AccentButton>
            ) : null}
          </Box>
        ) : null}
      </Box>
      <Box className={`${classes.lecture} ${classes.lecture__desktop}`}>
        {!isPaid ? <GetFullAccess isFree={isFree} /> : null}
        <Typography className={classes.title}>
          {pathname.includes("preparation-tasks")
            ? "Preparation tasks"
            : lecture?.data.coursesLectures?.title ||
              getCurrentLectureTitle(pages, lectureId)}
        </Typography>
        {pathname.includes("preparation-tasks") ? (
          promoImageUrl ? (
            <PreparationTasksImage src={promoImageUrl} />
          ) : null
        ) : !lecture && !getCurrentLectureIsFree(pages, lectureId) ? (
          <ClosedLectionImage src={promoImageUrl} />
        ) : lecture?.data.coursesLectures?.videoLink ? (
          <Player
            nextPage={nextPage}
            videoLink={lecture?.data.coursesLectures?.videoLink}
            videoTitle={lecture?.data.coursesLectures?.title}
            isPaid={isPaid}
            poster={promoImageUrl}
            lectureId={lectureId}
            isComplete={isCurrentLectureComplete}
            duration={currentLectureDuration}
            isCourseLoading={isCourseLoading}
            courseSlug={courseSlug}
          />
        ) : (
          <Poster poster={promoImageUrl} />
        )}
        {lecture?.data.coursesLectures?.linkTitle &&
        lecture?.data.coursesLectures?.link ? (
          <Workplace
            linkTitle={lecture.data.coursesLectures.linkTitle}
            link={lecture.data.coursesLectures.link}
          />
        ) : null}
        {lecture?.data.coursesLectures?.text ? (
          <ShowMore maxHeight={150} overlayColor="white">
            {!!lecture?.data?.coursesLectures.text
              .toString()
              .match(/<[^>]+>/) ? (
              <HTMLComponent html={lecture.data.coursesLectures.text} />
            ) : (
              <Typography className={classes.description}>
                {lecture.data.coursesLectures.text}
              </Typography>
            )}
          </ShowMore>
        ) : null}
        {!pathname.includes("preparation-tasks") && (nextPage || prevPage) ? (
          <Box className={classes.actions}>
            {prevPage ? (
              <WhiteButton
                isBig={false}
                variant="outlined"
                component={Link}
                to={`/courses/${courseSlug}/lecture/${prevPage?.id}`}
                onClick={() => {
                  handleOpen();
                }}
                startIcon={<ChevronLeftIcon />}
                className={classes.actions__prev}
              >
                Previous Lecture
              </WhiteButton>
            ) : null}
            {nextPage ? (
              <AccentButton
                isBig={false}
                variant="contained"
                component={Link}
                to={`/courses/${courseSlug}/lecture/${nextPage?.id}`}
                onClick={() => {
                  handleOpen();
                }}
                endIcon={<ChevronRightIcon />}
                className={classes.actions__next}
              >
                Next lecture
              </AccentButton>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </>
  );
}
