import { ThemeProvider } from "@mui/material/styles";
import { Router } from "./Router";
import { CssBaseline } from "@mui/material";
import { theme } from "./assets/theme";
import React, { useContext, useEffect, useLayoutEffect } from "react";
import { AuthContext } from "./context/AuthContext";
import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "./instances/axiosInstances";
import Loader from "./components/Common/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import errorToast from "./components/toasts/errorToast";
import { ModalContext } from "./context/ModalContext";
import { Modal } from "./components/MUI";
import smoothscroll from "smoothscroll-polyfill";
import ScrollToTop from "./components/Common/ScrollToTop";
import QuestionnaireModal from "./components/modal/QuestionnaireModal";
import DiscordButton from "./components/Common/DiscordButton";
import { QuestionnairePopupContext } from "./context/QuestionnairePopupContext";
import { useLocation, useNavigate } from "react-router-dom";
import WebinarPopup from "./components/Common/WebinarPopup";
import { SubscriptionsContext } from "./context/SubscriptionsContext";

export default function App() {
  const { showModal, modalContent, handleModalClose } =
    useContext(ModalContext);
  const { user, setAuthUser, isAuthLoading, setIsAuthLoading, auth } =
    useContext(AuthContext);
  const { setCurrentUser } = useContext(QuestionnairePopupContext);
  const {
    setCurrentSubscription,
    setSelectedSubscription,
    setIsCheckoutPopupOpen,
    setRedirectPath,
  } = useContext(SubscriptionsContext);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { mutate } = useMutation((data) => api.post("/auth/refresh", data), {
    onSuccess: ({ data }) => {
      setAuthUser(data);
      setIsAuthLoading(false);
    },
    onError: (error) => {
      errorToast(error.response.data.message, {
        autoClose: false,
        hideProgressBar: true,
      });

      localStorage.setItem("redirectPath", pathname);
      navigate("/login");

      setIsAuthLoading(false);
    },
  });

  useQuery({
    queryKey: ["user", user?.id],
    queryFn: () => api.get(`/users/${user.id}`),
    staleTime: Infinity,
    onSuccess: ({ data }) => {
      setCurrentUser(data?.users);
    },
    enabled: !!user?.id,
  });

  const { isFetching: isCurrentSubscriptionLoading } = useQuery(
    ["currentSubscription"],
    () => {
      return api.get(`/subscriptions`);
    },
    {
      staleTime: Infinity,
      retry: 3,
      onSuccess: ({ data }) => {
        setCurrentSubscription(data);
      },
      enabled: auth && !!user,
    }
  );

  useLayoutEffect(() => {
    setIsAuthLoading(!!localStorage.getItem("refreshToken"));
    localStorage.getItem("refreshToken") &&
      mutate({
        refreshToken: localStorage.getItem("refreshToken"),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    if (auth) {
      localStorage.removeItem("sub");
      localStorage.removeItem("createProfileToken");
      localStorage.removeItem("multipleUsageToken");
    } else {
      setCurrentSubscription(null);
      setSelectedSubscription(null);
      setIsCheckoutPopupOpen(false);
      setRedirectPath(undefined);
    }
  }, [auth]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `//js.hs-scripts.com/48295356.js`;
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    script.id = "hs-script-loader";
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {isAuthLoading || isCurrentSubscriptionLoading ? (
        <Loader autoplay={false} />
      ) : null}
      <CssBaseline />
      <Router
        isAuthenticated={auth}
        isAuthLoading={isAuthLoading}
        user={user}
      />
      <ToastContainer
        position="top-right"
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        theme="light"
        autoClose={5000}
      />
      <Modal open={showModal} handleClose={handleModalClose}>
        {modalContent}
      </Modal>
      <QuestionnaireModal />
      <WebinarPopup />
      <ScrollToTop />
      <DiscordButton />
    </ThemeProvider>
  );
}
