import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";
import {
  AccentButton,
  WhiteButton,
} from "../../../../../components/MUI/buttons/button";
import { Link, useParams } from "react-router-dom";
import { useContext, useEffect, useRef } from "react";
import useModal from "../../../../../components/Common/Modal/useModal";
import Modal from "../../../../../components/Common/Modal";
import CourseReview from "../../../../../components/Common/CourseReview";
import { SubscriptionsContext } from "../../../../../context/SubscriptionsContext";
import { AuthContext } from "../../../../../context/AuthContext";
export default function RecordedEnded({
  courseId,
  isFeedbackComplete,
  certificate,
  instructors,
}) {
  const { currentSubscription } = useContext(SubscriptionsContext);
  const modalRef = useRef();
  const { handleOpen, handleClose } = useModal(modalRef);
  const { user } = useContext(AuthContext);
  const { courseSlug } = useParams();
  const userId = user?.id;

  useEffect(() => {
    return () => {
      handleClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal modalRef={modalRef}>
        <CourseReview
          isFeedbackComplete={isFeedbackComplete}
          userId={userId}
          courseId={courseId}
          instructors={instructors}
          handleClose={handleClose}
        />
      </Modal>

      <Box className={classes.wrapper}>
        <Box>
          <Typography className={classes.title}>
            🤩 Congratulations, you have completed the course
          </Typography>
          <Typography className={classes.text}>
            You will still have access to the lectures.
          </Typography>
        </Box>
        <Box className={classes.actions}>
          {certificate ? (
            <AccentButton
              className={classes.button}
              isBig={false}
              variant="contained"
              component={Link}
              to={`/rating/${userId}/certificate/${certificate.id}`}
            >
              Go to certificate
            </AccentButton>
          ) : !currentSubscription?.plan.isFree ? (
            <AccentButton
              className={classes.button}
              isBig={false}
              variant="contained"
              onClick={handleOpen}
            >
              Generate certificate
            </AccentButton>
          ) : null}
          <WhiteButton
            className={classes.button}
            isBig={false}
            variant="outlined"
            component={Link}
            to={`/courses/${courseSlug}/lecture/preparation-tasks`}
          >
            Review lectures
          </WhiteButton>
        </Box>
      </Box>
    </>
  );
}
