import { Box } from "@mui/material";
import classes from "./index.module.scss";
import CourseCard from "../../../components/MUI/cards/CourseCard";
import NotFound from "../../../components/Common/NotFound";
import { useGTM } from "../../../hooks/useGTM";

export default function CoursesList({ courses, isLoading, isUserCourses }) {
  const { trackEvent } = useGTM();
  const handleCourseClick = (course) => {
    trackEvent({
      event: isUserCourses ? "user_course_click" : "common_course_click",
      action: "click",
      course: {
        id: course.id,
        title: course.title,
        type: course.type,
      },
    });
  };
  return (
    <Box className={classes.courses}>
      {courses && !isLoading
        ? courses.map((course) => (
            <CourseCard
              key={course.id}
              course={course}
              onClick={handleCourseClick}
            />
          ))
        : null}
      {(!courses || !courses.length) && !isLoading ? (
        <NotFound text={"Sorry, we couldn't find courses."} />
      ) : null}
    </Box>
  );
}
