export const links = [
  {
    label: "Courses",
    path: "/courses",
  },
  {
    label: "Events",
    path: "/events",
  },
  {
    label: "Pricing",
    path: "/subscriptions",
  },
];

