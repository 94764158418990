import qs from "qs";

export const promoCourseQuery = qs.stringify({
  populate: {
    sections: {
      on: {
        "promo-course.hero": {
          populate: "*",
        },
        "promo-course.description": {
          populate: {
            link: {
              populate: "*",
            },
            card: { populate: "*" },
            regular_price: true,
            current_price: true,
          },
        },
        "promo-course.why-us": {
          populate: {
            traditional_list: {
              populate: "*",
            },
            our_list: {
              populate: "*",
            },
          },
        },
        "promo-course.program": {
          populate: {
            link: {
              populate: "*",
            },
            regular_price: true,
            current_price: true,
            list: {
              populate: "*",
            },
            image: {
              populate: "*",
            },
          },
        },
        "promo-course.mentors": {
          populate: {
            card: { populate: "*" },
          },
        },
        "promo-course.for-answers-and-questions": {
          populate: {
            link: {
              populate: "*",
            },
            list: {
              populate: "*",
            },
            regular_price: true,
            current_price: true,
            description: {
              populate: "*",
            },
          },
        },
        "promo-course.for-who": {
          populate: {
            list: {
              populate: "*",
            },
            image: {
              populate: "*",
            },
          },
        },
        "promo-course.reminder": {
          populate: {
            link: {
              populate: "*",
            },
          },
        },
      },
    },
  },
});
