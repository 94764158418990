import React, { useContext } from "react";
import Logo from "../Logo";
import classes from "./index.module.scss";
import User from "../../../../Common/User";
import { mediaItems, menuItems } from "./menuItems";
import errorToast from "../../../../toasts/errorToast";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import { api } from "../../../../../instances/axiosInstances";
import { AuthContext } from "../../../../../context/AuthContext";
import { ReactComponent as CloseIcon } from "./images/close.svg";
import { useMutation } from "@tanstack/react-query";
import { RedirectContext } from "../../../../../context/RedirectContext";
import { ReactComponent as UniversityLogo } from "../../../../../assets/images/logo-white.svg";
import AuthButton from "../../../../Common/AuthButton/AuthButton";
import { WhiteButton } from "../../../../MUI/buttons/button";
import { useGTM } from "../../../../../hooks/useGTM";
import { QuestionnairePopupContext } from "../../../../../context/QuestionnairePopupContext";

export default function Content({ variant, handleDrawerToggle }) {
  const { auth, removeAuthUser, accessToken } = useContext(AuthContext);
  const { currentUser } = useContext(
    QuestionnairePopupContext
  );
  const { removeCurrentCourse } = useContext(RedirectContext);
  const user = auth ? currentUser : null;


  const { trackEvent } = useGTM();
  const handleTrackSocialButtonClick = (label) => {
    trackEvent({
      event: "sidebar_social_button_click",
      action: "click",
      label,
    });
  };
  const handleTrackLogoClick = () => {
    trackEvent({
      event: "sidebar_logo_click",
      action: "click",
      label: "Sidebar Logo",
    });
  };
  const handleTrackNavLinkClick = (label) => {
    trackEvent({
      event: "sidebar_navigation_link_click",
      action: "click",
      label,
    });
  };
  const handleTrackAuthButtonClick = () => {
    trackEvent({
      event: "sidebar_sign_up_login_button_click",
      action: "click",
      label: "Sign up / Login",
    });
  };
  const handleTrackLogoutButtonClick = () => {
    trackEvent({
      event: "sidebar_logout_button_click",
      action: "click",
      label: "Logout",
    });
  };
  const handleTrackUserClick = () => {
    trackEvent({
      event: "sidebar_user_click",
      action: "click",
      label: "User profile link",
    });
  };

  const { pathname } = useLocation();

  const { mutate } = useMutation((data) => api.post("/auth/logout", data), {
    onSuccess: () => {
      localStorage.setItem("redirectPath", pathname);
      removeAuthUser();
      removeCurrentCourse();
    },
    onError: (error) => {
      errorToast(error.response.data.message);
    },
  });

  const handleLogout = (e) => {
    e.preventDefault();
    mutate({
      token: accessToken || localStorage.getItem("accessToken"),
    });
  };

  const isLandingVariant = variant === "landing";

  return (
    <Box className={classes.content}>
      <Box className={classes.innerContent}>
        <Box className={classes.topBox}>
          <Logo
            handleDrawerToggle={handleDrawerToggle}
            handleTrackLogoClick={handleTrackLogoClick}
          >
            <Box className={classes.logo}>
              <UniversityLogo />
            </Box>
          </Logo>
          <button className={classes.closeBtn} onClick={handleDrawerToggle}>
            <CloseIcon />
          </button>
        </Box>

        <Divider
          sx={{ background: "var(--on-surface-a-030)", margin: "0 1rem" }}
        />

        {user ? (
          <>
            <Box className={classes.userBox}>
              <NavLink
                onClick={handleTrackUserClick}
                className={classes.navLink}
                to="/dashboard"
              >
                <User
                  fullName={user.fullName}
                  rating={user.rating}
                  icon={user.profilePhotoUrl}
                  isContentVisible={true}
                  iconWhite={true}
                  isDark={false}
                />
              </NavLink>
            </Box>
            <Divider
              sx={{ background: "var(--on-surface-a-030)", margin: "0 1rem" }}
            />
          </>
        ) : null}

        <Box className={classes.navList} component="ul">
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              {item.divider ? (
                <Divider
                  sx={{
                    background: "var(--on-surface-a-030)",
                    marginRight: "1rem",
                  }}
                />
              ) : (
                <>
                  {item.reqAuth && !auth ? null : (
                    <Box
                      className={`${classes.listItem} ${
                        pathname === item.link && classes.active
                      } ${!isLandingVariant && classes.noHover}`}
                      component="li"
                    >
                      {item.externalLink ? (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={item.link}
                          className={classes.navLink}
                          onClick={() => handleTrackNavLinkClick(item.name)}
                        >
                          <Box className={classes.innerItem}>
                            <Box className={classes.navIcon}>{item.icon}</Box>
                            <Typography className={classes.itemText}>
                              {item.name}
                            </Typography>
                          </Box>
                        </a>
                      ) : (
                        <NavLink
                          className={classes.navLink}
                          to={item.link}
                          onClick={() => {
                            handleDrawerToggle();
                            handleTrackNavLinkClick(item.name);
                          }}
                        >
                          <Box className={classes.innerItem}>
                            <Box className={classes.navIcon}>{item.icon}</Box>
                            <Typography className={classes.itemText}>
                              {item.name}
                            </Typography>
                          </Box>
                        </NavLink>
                      )}
                      <Box className={classes.itemIndicator}></Box>
                    </Box>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </Box>
      </Box>

      <Box className={classes.bottom}>
        {auth ? (
          <Box className={`${classes.authBtn} ${classes.logoutBtn}`}>
            <WhiteButton
              fullWidth
              isBig={false}
              variant="outlined"
              onClick={(e) => {
                handleLogout(e);
                handleTrackLogoutButtonClick();
              }}
            >
              Logout
            </WhiteButton>
          </Box>
        ) : null}

        <AuthButton
          handleTrackAuthButtonClick={handleTrackAuthButtonClick}
          variant="sidebar"
          className={classes.authButton}
        />

        <Box className={classes.mediaList} component="ul">
          {mediaItems.map((item, index) => (
            <Box className={classes.listItem} key={index} component="li">
              <a
                className={classes.navLink}
                href={item.link}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  handleDrawerToggle();
                  handleTrackSocialButtonClick(item.label);
                }}
              >
                <Box className={classes.mediaIcon}>{item.icon}</Box>
              </a>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
